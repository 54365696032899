import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "react-rangeslider/lib/index.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateAbout as onUpdateAbout,
  getAbout as onGetAbout,
} from "store/contacts/actions";
import toastr from 'toastr'; // toastr kütüphanesini içe aktarın
import 'toastr/build/toastr.min.css'; // toastr stillerini içe aktarın

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dropzone from 'react-dropzone-uploader';
import { Base64 } from 'js-base64';
import axios from "axios";

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file; // loader.file bir Promise nesnesi döndürür

          // Burada file değişkeni Promise'ın çözüldüğü (fulfilled) durumda, yani File nesnesini içerir
          const body = new FormData();
          body.append('dataFile', file);
          console.log("file1c2313",file);

          try {
            const response = await axios.request({
              method: "POST",
              url: `https://megaadmin.stechomeyazilim.info:8080/uploadfile`,
              data: body, // axios'ta "data" kullanın, "body" değil
              headers: {
                "Content-Type": "multipart/form-data"
              }
            });

            resolve({
              default: `https://megasubdomain.stechomeyazilim.info/${response.data.file.filename}`
            });
          } catch (error) {
            // Hata oluştuğunda throw ile hata fırlatın
            throw error;
          }

          // File nesnesini kullanarak istediğiniz işlemleri yapabilirsiniz
        } catch (error) {
          reject(error);
        }
      });
    },
    abort: () => {}
  };
}


function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

const EcommerceAddProduct = () => {
  document.title = "Hakkımızda Bilgileri | Bitti Gitti Admin Paneli";
 
  const dispatch = useDispatch();

  const [getImage, setImage] = useState(null);

  const [getContactEdit, setContactEdit] = useState(null);
  const [getHtmlDataLong, setHtmlDataLong] = useState(null);

  const [getHtmlDataPrivacy, setHtmlDataPrivacy] = useState(null);
  const [getHtmlDataKullanim, setHtmlDataKullanim] = useState(null);
  const [getHtmlDataNotification, setHtmlDataNotification] = useState(null);

  const showToast= async (type) => {
    let ele = null
    let message = "";
  
     if(type == true){
       ele = "success"
       message = "Başarıyla güncellendi."
    } else{
       ele = "error"
       message = "type"
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  const editorConfiguration = {
    toolbar: {
        items: [
            'fontFamily', // Font ailesi seçici
            'fontSize',   // Font boyutu seçici
            '|',
            'bold',
            'italic',
            'underline'
        ]
    },
    fontFamily: {
        options: [
            'default',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif'
        ]
    },
    fontSize: {
        options: [9, 11, 13, 'default', 17, 19, 21]
    }
};
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: (getContactEdit && getContactEdit.CompanyName) || '',
      phone: (getContactEdit && getContactEdit.PhoneNumber) || '',
      email: (getContactEdit && getContactEdit.Mail) || '',
      adress: (getContactEdit && getContactEdit.Address) || '',
      minPrice: (getContactEdit && getContactEdit.Min_CartPrice) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Lütfen Firma Adı Giriniz")
    }),
    onSubmit: (values) => {

      const updateOrder = {
        ID: users ? users[0].ID : 0,
        About_Notification:Base64.encode(getHtmlDataNotification),
        About_Desc: Base64.encode(getHtmlDataLong),
        About_Privacy:Base64.encode(getHtmlDataPrivacy),
        TermsofUse:Base64.encode(getHtmlDataKullanim),
        PhoneNumber:values.phone,
        CompanyName: values.name,
        Video:getImage,
        Mail: values.email,
        Address: values.adress,
        Min_CartPrice: values.minPrice,
      };
      // update order
      dispatch(onUpdateAbout(updateOrder));
      showToast(true)

     // validation.resetForm();

    },
  });
  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }
  const { users } = useSelector(state => ({
    users: state.contacts.about,
  }));

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetAbout());

    }
  }, [dispatch, users]);

  useEffect(() => {
    if (users.length > 0) {
      setContactEdit(users[0]);
      
      if(users[0].About_Privacy != null){
        setHtmlDataPrivacy(Base64.decode(users[0].About_Privacy))
      }
      if(users[0].TermsofUse != null){
        setHtmlDataKullanim(Base64.decode(users[0].TermsofUse))
      }
      if(users[0].About_Notification != null){
        setHtmlDataNotification(Base64.decode(users[0].About_Notification))
      }
      if(users[0].About_Desc != null){
        setHtmlDataLong(Base64.decode(users[0].About_Desc))
      }

      setImage(users[0].Video)
      
    }
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) {
      setContactEdit(users[0]);
    }
  }, [users]);
  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }


  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setImage(result.file.filename)

        }
      }
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Hakkımızda Bilgileri" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Hakkımızda Bilgileri</CardTitle>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="productname">Telefon Numarası</Label>
                        <Input
                          id="phone"
                          name="phone"
                          type="text"
                          className="form-control"
                          placeholder="Telefon Numarası"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone || ""}
                          invalid={
                            validation.touched.phone && validation.errors.phone ? true : false
                          }
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                        ) : null}

                      </div>
                      <div className="mb-3">
                        <Label htmlFor="productname">Firma Adı</Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Firma Adı"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name ? true : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                        ) : null}

                      </div>
            

                      <div className="mb-3">
                              <Col xs={12} className="mb-3">
                                <label htmlFor="resume">Görsel</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

                              </Col>
                            </div>

      {  getImage != null ?
              <div>
              <img
                className=" avatar-xl"
                src={'https://megasubdomain.stechomeyazilim.info/' + getImage}
                alt=""
              />
            </div>:
            null}
              

                            <div className="mb-3">
                        <Label className="form-label">Açıklama</Label>

                        <Form method="post">
                          <CKEditor
                            editor={ClassicEditor}
                            data={getHtmlDataLong}
                            config={{
                              // @ts-ignore
                              extraPlugins: [uploadPlugin]
                            }}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataLong(data)

                            }}
                          />
                        </Form>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Gizlilik Politikaları</Label>

                        <Form method="post">
                          <CKEditor
                            editor={ClassicEditor}
                            data={getHtmlDataPrivacy}
                            config={{
                              // @ts-ignore
                              extraPlugins: [uploadPlugin]
                            }}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataPrivacy(data)

                            }}
                          />
                        </Form>
                      </div>
                    
                      <div className="mb-3">
                        <Label className="form-label">Kullanım Koşulları</Label>

                        <Form method="post">
                          <CKEditor
                            editor={ClassicEditor}
                            data={getHtmlDataKullanim}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataKullanim(data)

                            }}
                          />
                        </Form>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Bilgilendirme Formu</Label>

                        <Form method="post">
                          <CKEditor
                            editor={ClassicEditor}
                            data={getHtmlDataNotification}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setHtmlDataNotification(data)

                            }}
                          />
                        </Form>
                      </div>


                      <div className="mb-3">
                        <Label htmlFor="adress">Adres</Label>
                        <Input
                          id="adress"
                          name="adress"
                          type="text"
                          className="form-control"
                          placeholder="Adres"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.adress || ""}
                          invalid={
                            validation.touched.adress && validation.errors.adress ? true : false
                          }
                        />
                        {validation.touched.adress && validation.errors.adress ? (
                          <FormFeedback type="invalid">{validation.errors.adress}</FormFeedback>
                        ) : null}

                      </div>

                   


                      <div className="mb-3">
                        <Label htmlFor="email">Eposta</Label>
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          className="form-control"
                          placeholder="Eposta Adresi"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}

                      </div>


                      <div className="mb-3">
                        <Label htmlFor="email">Min Sepet Tutarı</Label>
                        <Input
                          id="minPrice"
                          name="minPrice"
                          type="number"
                          className="form-control"
                          placeholder="Min. Sepet Tutarı"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.minPrice || ""}
                          invalid={
                            validation.touched.minPrice && validation.errors.minPrice ? true : false
                          }
                        />
                        {validation.touched.minPrice && validation.errors.minPrice ? (
                          <FormFeedback type="invalid">{validation.errors.minPrice}</FormFeedback>
                        ) : null}

                      </div>

                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Kaydet
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct

import axios from 'axios';
import PropTypes from 'prop-types'
import React, {useState,useEffect} from "react"
import { Modal, ModalBody, Label,Input } from "reactstrap"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,

  ModalHeader,


  FormFeedback,
  UncontrolledTooltip,

  Form,
} from "reactstrap";

const CompanyModal = ({ show, onFilterClick, onCloseClick,handleChange,handleChangeFinish,onDownloadClick }) => {
  const [getCategoryCompany, setCategoryCompany] = useState([]);
  const [selectedValuesCompany, setSelectedValuesCompany] = useState(null);


  const _getAuthDataCompany = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getAllRestBiti/select/`)
      .then((res) => {
        setCategoryCompany(res.data)
      })
  }

  const handleSelectChangeCompany = (event) => {
    console.log("snd123kfl",event.target.value)

    setSelectedValuesCompany(event.target.value)

  }

  useEffect(() => {
    _getAuthDataCompany()
  }, [getCategoryCompany]);
  return (
    <Modal size="sm" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
         

          <div className="mb-3">
                              <Label className="form-label">Firma Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={selectedValuesCompany}
                                onChange={(text) => handleSelectChangeCompany(text)}
                                value={
                                  selectedValuesCompany
                                }>
                                {getCategoryCompany.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.ResName}</option>
                          ))}

                              </Input>
                            
                            </div>


          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" className="btn btn-danger" onClick={()=> onFilterClick(selectedValuesCompany)}>Filtrele</button>
            <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Kapat</button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

CompanyModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default CompanyModal

import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Email, Date, Price } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getTeacherMega  as onGetTeacher,
  addNewTeacher as onAddNewTeacher,
  updateTeacher as onUpdateTeacher,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";

const ContactsList = props => {

  //meta title
  document.title = "Hesap Bakiye | BITTIGITTI ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [getEditContactTeacher, setEditContactTeacher] = useState();
  const [getIsActive, setIsActive] = useState(false);
  const [getCategory, setCategory] = useState([]);
  const [getRestID, setRestID] = useState(null);

  

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      totalPrice: (getEditContactTeacher && getEditContactTeacher.TotalPrice) || "",
      paymentNote: (getEditContactTeacher && getEditContactTeacher.Rest_Payment_Note) || "",
    },
    validationSchema: Yup.object({
     
    }),
    onSubmit: values => {
      if (isEdit) {
        let getData = localStorage.getItem("authUser");

        const updateUser = {
          ID: contact.ID,
          Rest_ID:getRestID,
          Users_ID:	parseInt(JSON.parse(getData).ID),
          Created_DateTime:	moment().format('YYYY-MM-DDTHH:mm:ssZ'),
          TotalPrice:	values.totalPrice,
          Rest_Payment_Note: values.paymentNote,
          Rest_Payment_Type: getIsActive == true ? "borç" : "alacak"
        };
        // update user
        dispatch(onUpdateTeacher(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        let getData = localStorage.getItem("authUser");

        const newUser = {
          Rest_ID:getRestID,
          Users_ID:	JSON.parse(getData).ID,
          Created_DateTime:	moment().format('YYYY-MM-DDTHH:mm:ssZ'),
          TotalPrice:	values["totalPrice"],
          Rest_Payment_Note: values["paymentNote"],
          Rest_Payment_Type: getIsActive == true ? "borç" : "alacak"
        };
       
        console.log("newUser123",newUser)
        dispatch(onAddNewTeacher(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.teacher,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [getRestCheck, setRestCheck] = useState(false);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Ekleyen Kişi",
        accessor: "Users.Users_Name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Rest. Adı",
        accessor: "Rest.ResName",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },

      {
        Header: "Türü",
        accessor: "Rest_Payment_Type",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },

      {
        Header: "Tutar",
        accessor: "TotalPrice",
        filterable: true,
        Cell: cellProps => {
          return <Price {...cellProps} />;
        },
      },
      

      {
        Header: "Not",
        accessor: "Rest_Payment_Note",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },

      {
        Header: 'Kayıt Tarihi',
        accessor: 'Created_DateTime',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
             getRestCheck == true ? 
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div> : null 
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getRestBitti/select/`)
      .then((res) => {
        setCategory(res.data)
      })


  }

  const _setChoiceCategory = arg => {
    setRestID(arg.target.value)
  }


  useEffect(() => {
    if (users && !users.length) {
      _getAuthData()

      if(JSON.parse(localStorage.getItem("authUser")).UserTypeID == 3){
        setRestCheck(true)
        dispatch(onGetTeacher(JSON.parse(localStorage.getItem("authUser")).Rest[0].ID));
        setIsEdit(false);
      }else{
        dispatch(onGetTeacher(null));
        setIsEdit(false);
      }
     
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setEditContactTeacher(user)
    setContact(user);
    setIsEdit(true);
    setRestID(user.Rest_ID)
    
    if(user.Rest_Payment_Type == "borç"){
      setIsActive(true)
    }else{
      setIsActive(true)
    }
    
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.id) {
      dispatch(onDeleteUser(contact.id));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Anasayfa" breadcrumbItem="Hesap Bakiye" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddTeacher={getRestCheck == true ? false : true}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Bakiye Düzenle" : "Bakiye Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >      

                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Total Ücret</Label>
                              <Input
                                name="totalPrice"
                                type="number"
                                placeholder="Total Ücret"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.totalPrice || ""}
                                invalid={
                                  validation.touched.totalPrice &&
                                    validation.errors.totalPrice
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.totalPrice &&
                                validation.errors.totalPrice ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.totalPrice}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Not</Label>
                              <Input
                                name="paymentNote"
                                type="text"
                                placeholder="Not Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.paymentNote || ""}
                                invalid={
                                  validation.touched.paymentNote &&
                                    validation.errors.paymentNote
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.paymentNote &&
                                validation.errors.paymentNote ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.paymentNote}
                                </FormFeedback>
                              ) : null}
                            </div>
                          
                            <div className="mb-3">
                              <Label className="form-label">Rest. Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getRestID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getRestID
                                }>
                               <option value="">Lütfen Seçim Yapınız</option>
                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.ResName}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Borç "
                              data-off-label="Alacak"
                            />
                          </div>

                           
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);

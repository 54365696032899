import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname, Status } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';

import "jspdf-autotable";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dropzone from 'react-dropzone-uploader';
import { Base64 } from 'js-base64';
import DateModal from "components/Common/DateModal";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import {ExportToExcel2} from '../../../ExportToExcel2'


import {
  getProduct as onGetProduct,
  addProduct as onAddProduct,
  updateProduct as onUpdateProduct,
  deleteProduct as onDeleteProduct,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import { Price } from "../Orders/contactlistCol";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Ürünler | BİTTİ GİTTİ ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState(null);

  const [getHtmlDataLong, setHtmlDataLong] = useState();
  const [getFileSubMedia, setFileSubMedia] = useState([]);
  const [getFileSubMediaAdd, setFileSubMediaAdd] = useState([]);
  const [getFileName, setFileName] = useState(null);
  const [getSubMediaID, setSubMediaID] = useState();
  const [getIsActive, setIsActive] = useState(false);

  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Products_Name) || "",
      price: (contactEdit && contactEdit.Price) || null,
      disprice: (contactEdit && contactEdit.DiscountedPrice) || null,
      stock: (contactEdit && contactEdit.Stock) || null,
      desc: (contactEdit && contactEdit.ProductsDesc) || null,
    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Başlığı Giriniz"),
      price: Yup.number().nullable().moreThan(99, "Fiyat 100 TL'den büyük olmalıdır"),
      disprice: Yup.number().nullable().moreThan(99, "İndirimli fiyat 100 TL'den büyük olmalıdır"),
      stock: Yup.number().nullable().integer().positive().required("Stok miktarını giriniz"),
      desc: Yup.string().nullable()

    }),
    onSubmit: values => {
      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Products_Name: values.title,
          ProductsDesc: values.desc,
          Product_Category_ID: getCategoryID,
          Price: values.price,
          DiscountedPrice: values.disprice,
          Stock: values.stock,
          ImageCover: getFileName,
          IsActive:getIsActive,
          MobileCheck : getFileName != null ? false : true

        };
        dispatch(onUpdateProduct(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Products_Name: values["title"],
          ProductsDesc: values["desc"],
          Product_Category_ID: getCategoryID,
          Price: values["price"],
          DiscountedPrice: values["disprice"],
          Stock: values["stock"],
          ImageCover: getFileName,
          IsActive:getIsActive,
          MobileCheck : getFileName != null ? false : true
        };
        // save new user
        dispatch(onAddProduct(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.products,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);


  const [getCategoryID, setCategoryID] = useState(null);


  const [getCategory, setCategory] = useState([]);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Foto",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.ImageCover ? (
              <div className="avatar-xs">
                {cellProps.Products_Name != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Products_Name.charAt(0)}
                </span> : null}
              </div>
            ) : (
              cellProps.MobileCheck == true ? 
              <div>
              <img
                className="rounded-circle avatar-xs"
                src={'https://kampanya.stechomeyazilim.com/Uploads/' + cellProps.ImageCover}
                alt=""
              />
            </div>:
              <div>
                <img
                  className="rounded-circle avatar-xs"
                  src={'https://megasubdomain.stechomeyazilim.info/' + cellProps.ImageCover}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Adı",
        accessor: "Products_Name",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Stok",
        accessor: "Stock",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "FİYAT",
        accessor: "Price",
        filterable: true,
        Cell: cellProps => {
          return <Price {...cellProps} />;
        },
      },

      {
        Header: "İndirimli Fiyat",
        accessor: "DiscountedPrice",
        filterable: true,
        Cell: cellProps => {
          return <Price {...cellProps} />;
        },
      },
      
      {
        Header: "Aktiflik",
        accessor: "IsActive",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },


      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

            
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getProduct_CategoryBitti/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  useEffect(() => {
    _getAuthData()
    if (users && !users.length) {
      dispatch(onGetProduct());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };


  const handleUserClickMedia = (arg) => {
    const user = arg;

    setSubMediaID(user.ID)

    setFileSubMedia(user.Products_Image)

    toggleMedia();
  };


  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

if(user.Product_Category_ID != null){
  setCategoryID(user.Product_Category_ID)
}

    if (user.IsActive != null) {
      setIsActive(user.IsActive)
    }

   
    setIsEdit(true);


    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteProduct(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const _deleteMedia = async (itemData) => {

    try {
          
    axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteProducts_ImageBitti/${itemData.ID}`).then(async(res) => {
      
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getProducts_ImageBitti/select/${getSubMediaID}`)
    .then((res) => {
      setFileSubMedia(res.data)
    })

    
    })
    } catch (error) {
      //yield put(updateUserFail(error))
    }


  /*  const filteredData = getFileSubMediaAdd.filter(item => item.ID !== itemData.ID )
    setFileSubMediaAdd(filteredData);*/

  }

  const _addMedia = async() => {


    getFileSubMediaAdd.map(async(item, index) => {
      const newUser = {
        Products_ID: getSubMediaID,
        //EducationSubItem_Media_Type: item.fileType,
        Url: item.fileName
      }

      axios.post('https://getjob.stechomeyazilim.info:5101/addProducts_ImageBitti/send', newUser)

      await axios.get(`https://getjob.stechomeyazilim.info:5101/getProducts_ImageBitti/select/${getSubMediaID}`)
      .then((res) => {
        setFileSubMedia(res.data)
      })

      dispatch(onGetProduct());

      toggleMedia();
    })
  }

  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }
  
  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response)
          setFileSubMediaAdd(oldArray => [{ fileName: result.file.filename, fileType: result.file.mimetype }, ...oldArray]);
        }
      }
    }
  }
  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }


  const [getExcelModal, setExcelModal] = useState(false);
  const [getSelectDate, setSelectDate] = useState(null);
  const [getSelectDateFinish, setSelectDateFinish] = useState(null);

    
  const exportToCSV = () => {

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = getSelectDate
    let getSelectDateDaysFinish = moment(getSelectDateFinish).add(1, 'days').format('YYYY/MM/DD')

    console.log("klsdmkşlf",getSelectDate,getSelectDateDaysFinish)

  const data2 =  users.filter(x=> x.Created_DateTime > getSelectDate && x.Created_DateTime < getSelectDateDaysFinish).map(elt=> [
    elt.Campain_Code,
    elt.Campain.Campain_Title,
    elt.Users.Company != null ? elt.Users.Company.Company_Title : null,
      ]);

    const ws = XLSX.utils.json_to_sheet(data2);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }


  const handleChangeTextFinish = (text) => {

    setSelectDateFinish(text.target.value)

  }
      const handleChangeText = (text) => {

      setSelectDate(text.target.value)

    }

    const onClickExcel = (order) => {
      setDateClick(order);
      setExcelModal(true);
    }
    const data2 =  users.map(elt=> [
      elt.Products_Name,
      ]);

  return (
    <React.Fragment>
      <DateModal
        onDownloadClick={exportToCSV}
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}

        show={getExcelModal}
        onCloseClick={() => setExcelModal(false)}
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Ürün Listesi" />

          <ExportToExcel2 title={"Tarih Excel"} onDownloadClick={exportToCSV} onClickExcel={onClickExcel} apiData={data2} fileName={"fileName"} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddProduct={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal  size="lg" isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Ürün Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                        <Col lg={6}>


                            <div className="mb-3">
                              <Label className="form-label">Ürün Adı</Label>
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                placeholder="Ürün Adı Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>


                          {/**<Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Kategori Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                                  <option value="">Lütfen Seçim Yapınız</option>
                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Product_Category_Title}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>
                            </Col> */}  

                            <Col lg={6}>
                              <div className="mb-3">
                              <Label className="form-label">Ürün Adı</Label>
                              <Input
                                name="desc"
                                label="desc"
                                type="text"
                                placeholder="Ürün Adı Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.desc || ""}
                                invalid={
                                  validation.touched.desc &&
                                    validation.errors.desc
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.desc &&
                                validation.errors.desc ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.desc}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                             
                            <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Fiyat</Label>
                              <Input
                                name="price"
                                label="price"
                                type="text"
                                placeholder="Fiyat Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.price || ""}
                                invalid={
                                  validation.touched.price &&
                                    validation.errors.price
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.price &&
                                validation.errors.price ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.price}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>

                            <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">İndirimli Fiyat</Label>
                              <Input
                                name="disprice"
                                label="disprice"
                                type="text"
                                placeholder="İndirimli Fiyat Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.disprice || ""}
                                invalid={
                                  validation.touched.disprice &&
                                    validation.errors.disprice
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.disprice &&
                                validation.errors.disprice ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.disprice}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>
                            
                            <Col lg={6}>
                            <div className="mb-3">
                              <Label className="form-label">Stok Giriniz</Label>
                              <Input
                                name="stock"
                                label="stock"
                                type="text"
                                placeholder="Stok Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.stock || ""}
                                invalid={
                                  validation.touched.stock &&
                                    validation.errors.stock
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.stock &&
                                validation.errors.stock ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.stock}
                                </FormFeedback>
                              ) : null}
                            </div>
                            </Col>
                            
                            <Col lg={6}>
                            <div className="d-flex">
                              <label htmlFor="resume">Aktif Mi?</label>

                              <div className="square-switch">

                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  className="switch"
                                  defaultChecked={getIsActive}
                                  onChange={() =>
                                    setIsActive(!getIsActive)
                                  }
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label="Aktif "
                                  data-off-label="Pasif"
                                />
                              </div>

                            </div>
                            </Col>

                            <Col lg={6}>
                            <div className="mb-3">
                              <Col xs={12} className="mb-3">
                                <label htmlFor="resume">Görsel</label>
                                <Dropzone
                                  getUploadParams={getUploadParams}
                                  onChangeStatus={handleChangeStatus}
                                  styles={{
                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                    inputLabelWithFiles: { margin: '20px 3%' }
                                  }}

                                  canRemove={false}
                                  PreviewComponent={Preview}
                                  accept="image/*"
                                />

                              </Col>
                            </div>
                            </Col>


{                           contactEdit != null ? contactEdit.MobileCheck == true ? 
               <Col lg={6}>
              <img
                className=" avatar-xl"
                src={'https://kampanya.stechomeyazilim.com/Uploads/' + contactEdit.ImageCover}
                alt=""
              />
            </Col>:
              <div>
                <img
                  className=" avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' + contactEdit.ImageCover}
                  alt=""
                />
              </div> : null}
              
                        
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);

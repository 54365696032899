import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {GET_SIPARIS, DELETE_SIPARIS,UPDATE_SIPARIS,ADD_SIPARIS,
  GET_SSS_CATEGORY, DELETE_SSS_CATEGORY,UPDATE_SSS_CATEGORY,ADD_SSS_CATEGORY,
  GET_COMPANY, DELETE_COMPANY,UPDATE_COMPANY,ADD_COMPANY,
  GET_REST_ADRESS, DELETE_REST_ADRESS,UPDATE_REST_ADRESS,ADD_REST_ADRESS,
  GET_ADRESS, DELETE_ADRESS,UPDATE_ADRESS,ADD_ADRESS,
  GET_REST_CATEGORY, DELETE_REST_CATEGORY,UPDATE_REST_CATEGORY,ADD_REST_CATEGORY,
  GET_REST_PRODUCT, DELETE_REST_PRODUCT,UPDATE_REST_PRODUCT,ADD_REST_PRODUCT,
  GET_REST, DELETE_REST,UPDATE_REST,ADD_REST,
  GET_ORDERS, DELETE_ORDERS,UPDATE_ORDERS,ADD_ORDERS, GET_ENG, DELETE_ENG,UPDATE_ENG,ADD_ENG, GET_CONS, DELETE_CONS,UPDATE_CONS,ADD_CONS, GET_AVAILABLE, DELETE_AVAILABLE,UPDATE_AVAILABLE,ADD_AVAILABLE,GET_OFFER, DELETE_OFFER,UPDATE_OFFER,ADD_OFFER, GET_BLOGS, GET_RESERVATION, DELETE_RESERVATION,UPDATE_RESERVATION,ADD_RESERVATION,GET_SLİDERS,GET_SSS,DELETE_SSS,ADD_SSS,UPDATE_SSS, DELETE_DYNAMICFORM, DELETE_PRODUCTCATEGORY, DELETE_DYNAMICFORMEXTRA, DELETE_COURSESCHEDULES,
  DELETE_GALLERY, DELETE_NEWS, GET_DYNAMICFORM_EXTRA_EXTRA, GET_DYNAMICFORM_EXTRA, UPDATE_DYNAMICFORM, GET_CLASSES, GET_CLASSATTENDANCE,
  UPDATE_LESSONS_TIME, UPDATE_CONTACT, UPDATE_ABOUT, GET_TEACHER_WORKSHOP, GET_SUBPROJECT, UPDATE_REFERANCES, ADD_REFERANCES, GET_REFERANCES, ADD_WORKSHOP2, ADD_APPSETTING,
  ADD_WORKSHOPSUBDOMAIN, UPDATE_GALLERY, GET_GALLERY, UPDATE_WORKSHOP2, UPDATE_MEGA, UPDATE_AUTH, GET_AUTHORITY, GET_CONTACT,GET_ABOUT,
  UPDATE_WORKSHOPSESSION, UPDATE_SUBDOMAIN, ADD_COURSE, ADD_MEGA, GET_MEGA, GET_NEWS, GET_PROJECTSNEW, GET_SLIDER, GET_DYNAMICFORM, GET_EVENT,
  GET_TeacherMega, GET_WorkshopSessionMega, GET_CourseSchedulesMega, GET_POPUP, GET_USERS, GET_USER_PROFILE,
  ADD_NEW_USER, ADD_USER,DELETE_USER, UPDATE_USER, GET_WORKSHOP, ADD_POPUP, ADD_PRODUCTCATEGORY, ADD_SLIDER,
  ADD_PROJECT, ADD_NEWS, UPDATE_NEWS, UPDATE_TEACHER, UPDATE_COURSESCHEDULES, UPDATE_SLIDER, UPDATE_PROJECT, GET_WORKSHOP2,
  ADD_LESSONS_TIME, ADD_DYNAMIC_FORM, DELETE_MEGA, DELETE_SLIDER,DELETE_BLOG, DELETE_POPUP, DELETE_REFERANCES, ADD_GALLERY,
  ADD_GALLERYMULTIPLE, DELETE_DYNAMICFORMEXTRAEXTRA, GET_EDUCATION, GET_SUBEDUCATION, UPDATE_EDUCATION, ADD_EDUCATION,
  UPDATE_SUBEDUCATION, ADD_SUBEDUCATION, DELETE_SUBEDUCATION, GET_PRODUCTCATEGORY, GET_PRODUCT, ADD_BLOG, UPDATE_PRODUCTCATEGORY,UPDATE_BLOG,ADD_PRODUCT,UPDATE_PRODUCT,DELETE_PRODUCT, ADD_NEW_TEACHER
} from "./actionTypes"

import {
  addOrdSuccess,
  updateOrdSuccess,
  getOrdSuccess,
  deleteOrdSuccess,
  addCompanySuccess,
  updateCompanySuccess,
  getCompanySuccess,
  deleteCompanySuccess,
  addRest_ProductSuccess,
  updateRest_ProductSuccess,
  getRest_ProductSuccess,
  deleteRest_ProductSuccess,

  addRestAdressSuccess,
  updateRestAdressSuccess,
  getRestAdressSuccess,
  deleteRestAdressSuccess,

  addSSSCategorySuccess,
  updateSSSCategorySuccess,
  getSSSCategorySuccess,
  deleteSSSCategorySuccess,

  addAdressSuccess,
  updateAdressSuccess,
  getAdressSuccess,
  deleteAdressSuccess,

  addRest_CategorySuccess,
  updateRest_CategorySuccess,
  getRest_CategorySuccess,
  deleteRest_CategorySuccess,

  addRestSuccess,
  updateRestSuccess,
  getRestSuccess,
  deleteRestSuccess,
  addEngSuccess,
  updateEngSuccess,
  getEngSuccess,
  deleteEngSuccess,
  addOfferSuccess,
  updateOfferSuccess,
  getOfferSuccess,
  deleteOfferSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  addSliderSuccess,
  addConsSuccess,
  updateConsSuccess,
  getConsSuccess,
  deleteConsSuccess,
  addSSSSuccess,
  updateSSSSuccess,
  getSSSSuccess,
  deleteSSSSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getPopupSuccess,
  getWorkshopSuccess,
  getWorkshopSessionSuccess,
  getApplicationSuccess,
  getSliderSuccess,
  getProjectSuccess,
  getNewsSuccess,
  getMegaSuccess,
  getAboutSuccess,
  getTeacherSuccess,
  getDynamicSuccess,
  getAuthSuccess,
  getAuthUpdateSuccess,
  updateSubdomainSuccess,
  updateSuccessCourseSchedules,
  updateWorkShopSessionSuccess,
  updateTeacherSuccess,
  updateSliderSuccess,
  updateSuccessProject,
  updateContactSuccess,
  updateAboutSuccess,
  updateNewsSuccess,
  updateBlogSuccess,
  updateMegaSuccess,
  getWorkshop2Success,
  updateWorkShop2Success,
  getGallerySuccess,
  updateGallerySuccess,
  getReferancesSuccess,

  addSuccessReferances,
  updateSuccessReferances,
  getSubProjectSuccess,
  getTeacherWorkshopSuccess,
  addSuccessLessonsTime,
  updateLessonsTimeSuccess,
  getClassAttendanceSuccess,
  getClassesSuccess,
  updateDynamicFormSuccess,
  addDynamicFormSuccess,
  getDynamicFormExtraSuccess,
  getDynamicFormExtraExtraSuccess,

  addMegaSuccess,
  deleteMegaSuccess,
  deleteProductCategorySuccess,
  deleteNewsSuccess,
  deleteSliderSuccess,
  deleteBlogSuccess,
  deletePopupSuccess,
  deleteReferancesSuccess,
  addGallerySuccess,
  deleteGallerySuccess,
  deleteCourseSchedulesSuccess,
  deleteDynamicFormSuccess,
  deleteDynamicExtraFormSuccess,

  deleteDynamicExtraExtraFormSuccess,
  getEducationSuccess,
  getSubEducationSuccess,
  updateEducationSuccess,
  addEducationSuccess,
  updateSubEducationSuccess,
  addSubEducationSuccess,
  deleteSubEducationSuccess,
  getProductCategorySuccess,
  getProductSuccess,
  getSlidersSuccess,
  getUsersSuccess,
  getBlogsSuccess,
  addProductCategorySuccess,
  addBlogSuccess,
  addProductSuccess,
  deleteProductSuccess,
  updateProductSuccess,
  addUser,
  addReservationSuccess,
  deleteReservationSuccess,
  updateReservationSuccess,
  getReservationSuccess,
  addAvailableSuccess,
  deleteAvailableSuccess,
  updateAvailableSuccess,
  getAvailableSuccess,
  addSiparisSuccess,
  deleteSiparisSuccess,
  updateSiparisSuccess,
  getSiparisSuccess,
  addNewTeacherSuccess,

  

} from "./actions"

//Include Both Helper File with needed methods
import {
  addNewTeacher,
  addRest,
  updateRest,getRest,
  deleteRest,
  addCompany,
  updateCompany,getCompany,
  deleteCompany,
  addSSSCategory,
  updateSSSCategory,getSSSCategory,
  deleteSSSCategory,
  addRestAdress,
  updateRestAdress,getRestAdress,
  deleteRestAdress,
  addRest_Product,
  updateRest_Product,getRest_Product,
  deleteRest_Product,
  addRest_Category,
  updateRest_Category,getRest_Category,
  deleteRest_Category,
  addSiparis,
  updateSiparis,getSiparis,
  deleteSiparis,
  addEng,
  updateEng,getEng,
  deleteEng,addCons,
  updateCons,getCons,
  deleteCons,updateAvailable,deleteAvailable,addAvailable ,getAvailable,updateOffer,deleteOffer,addOffer ,getOffer,updateReservation,deleteReservation,addReservation ,getReservation, updateProduct,deleteProduct,addProduct ,updateProductCategory,updateAbout, getSSS,addSSS,updateSSS,deleteSSS,getBlogs, getSliders, getProductCategory, deleteSubEducation, deleteBlog, deleteProductCategory, addSubEducation,addBlog, updateSubEducation, addEducation, updateEducation, getEducationSubItem, getEducation, deleteDynamicFormExtraExtra, deleteDynamicForm, deleteDynamicFormExtra, deleteCourseSchedules, addMultipleGalleryForm, deleteGallery, addGallerry, addProductCategory, deleteReferances, deletePopup, deleteSlider, deleteNews, deleteMega, getDynamicFormExtraExtraMega, getDynamicFormExtraMega, addDynamicForm, updateDynamicForm, getClassesMega, getClassAttendance, updateLessonsTime, addLessonsTime, getTeacherWorkshop, getSubProject, updateReferances, addReferances, getReferances, addWorkshop2, addAppSetting, addNewSubdomain, updateGallery, getGallery, updateWorkShop2, getWorkshop2, updateMega, updateProject, updateSlider, updateAuth, getAuthority, updateContact, getContact, getAbout,updateBlog, updateTeacher, updateWorkshopSession, updateSubdomain, addCourseMega, updateCourseSchedules, updateNews, addMega, addNews, addProject, addSlider, addPopup, getMegaMega, getNews, getProjectsNew, getSlider, getDynamicFormMega, getEventMega, getTeacherMega, getWorkshopSessionMega, getPopup, getUsers, getUserProfile, addNewUser, updateUser, deleteUser, getWorkShop, getCourseSchedulesMega, getProducts1, getRestAdressRest, getRest_ProductRest, getCompanyRest, getSiparisRest, getTeacherMega1 } from "../../helpers/fakebackend_helper"

function* onDeleteSubEducation({ payload: user }) {
  try {
    const response = yield call(deleteSubEducation, user)
    yield put(deleteSubEducationSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteAvailable({ payload: user }) {
  try {
    const response = yield call(deleteAvailable, user)
    yield put(deleteAvailableSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddAvailable({ payload: user }) {
  try {
    const response = yield call(addAvailable, user)

    yield put(addAvailableSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onAddSlider({ payload: user }) {
  try {
    const response = yield call(addSlider, user)

    yield put(addSliderSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetAvailable(item) {
  try {
    const response = yield call(getAvailable, item.users)
    yield put(getAvailableSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateAvailable({ payload: user }) {
  try {
    const response = yield call(updateAvailable, user)
    yield put(updateAvailableSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}



function* onDeleteCompany({ payload: user }) {
  try {
    const response = yield call(deleteCompany, user)
    yield put(deleteCompanySuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddCompany({ payload: user }) {
  try {
    const response = yield call(addCompany, user)

    yield put(addCompanySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetCompany(item) {
  try {

    console.log("klsdkf",item.payload)
    if(item.payload == null){
    const response = yield call(getCompany, item.users)
    yield put(getCompanySuccess(response))
    }else{
      const response = yield call(getCompanyRest, item.users)
      yield put(getCompanySuccess(response))
    }
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateCompany({ payload: user }) {
  try {
    const response = yield call(updateCompany, user)
    yield put(updateCompanySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}








function* onDeleteOrd({ payload: user }) {
  try {
    const response = yield call(deleteOrd, user)
    yield put(deleteOrdSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddOrd({ payload: user }) {
  try {
    const response = yield call(addOrd, user)

    yield put(addOrdSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetOrd(item) {
  try {
    const response = yield call(getOrd, item.users)
    yield put(getOrdSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateOrd({ payload: user }) {
  try {
    const response = yield call(updateOrd, user)
    yield put(updateOrdSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}




function* onDeleteAdress({ payload: user }) {
  try {
    const response = yield call(deleteAdress, user)
    yield put(deleteAdressSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddAdress({ payload: user }) {
  try {
    const response = yield call(addAdress, user)

    yield put(addAdressSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetAdress(item) {
  try {
    const response = yield call(getAdress, item.users)
    yield put(getAdressSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateAdress({ payload: user }) {
  try {
    const response = yield call(updateAdress, user)
    yield put(updateAdressSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onDeleteRest({ payload: user }) {
  try {
    const response = yield call(deleteRest, user)
    yield put(deleteRestSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddRest({ payload: user }) {
  try {
    const response = yield call(addRest, user)

    yield put(addRestSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetRest(item) {
  try {

    if(item.payload == null){
      const response = yield call(getRest, item.payload)
      yield put(getRestSuccess(response))
      }else{
        const response = yield call(getCompanyRest, item.payload)
    yield put(getRestSuccess(response))
      }

  
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateRest({ payload: user }) {
  try {
    const response = yield call(updateRest, user)
    yield put(updateRestSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteRest_Adress({ payload: user }) {
  try {
    const response = yield call(deleteRestAdress, user)
    yield put(deleteRestAdressSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddRest_Adress({ payload: user }) {
  try {
    const response = yield call(addRestAdress, user)

    yield put(addRestAdressSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetRest_Adress(item) {
  try {
    console.log("lnsdlkf",item.payload)

    if(item.payload != null){
      const response = yield call(getRestAdressRest, item.payload)
  
      yield put(getRestAdressSuccess(response))
    }else{
      const response = yield call(getRestAdress, item.payload)


      //getRestAdressRest
  
      yield put(getRestAdressSuccess(response))
    }

  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateRest_Adress({ payload: user }) {
  try {
    const response = yield call(updateRestAdress, user)
    yield put(updateRestAdressSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}





function* onDeleteRest_Product({ payload: user }) {
  try {
    const response = yield call(deleteRest_Product, user)
    yield put(deleteRest_ProductSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddRest_Product({ payload: user }) {
  try {
    const response = yield call(addRest_Product, user)

    yield put(addRest_ProductSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetRest_Product(item) {
  try {

    console.log("lsndlfk",item.payload)
    if(item.payload == null){

    const response = yield call(getRest_Product, item.payload)
    yield put(getRest_ProductSuccess(response))
    }
    else{
      const response = yield call(getRest_ProductRest, item.payload)
      yield put(getRest_ProductSuccess(response))
    }


   
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateRest_Product({ payload: user }) {
  try {
    const response = yield call(updateRest_Product, user)
    yield put(updateRest_ProductSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}








function* onDeleteRest_Category({ payload: user }) {
  try {
    const response = yield call(deleteRest_Category, user)
    yield put(deleteRest_CategorySuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddRest_Category({ payload: user }) {
  try {
    const response = yield call(addRest_Category, user)

    yield put(addRest_CategorySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetRest_Category(item) {
  try {
    const response = yield call(getRest_Category, item.users)
    yield put(getRest_CategorySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateRest_Category({ payload: user }) {
  try {
    const response = yield call(updateRest_Category, user)
    yield put(updateRest_CategorySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}





function* onDeleteSSSCategory({ payload: user }) {
  try {
    const response = yield call(deleteSSSCategory, user)
    yield put(deleteSSSCategorySuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddSSSCategory({ payload: user }) {
  try {
    const response = yield call(addSSSCategory, user)

    yield put(addSSSCategorySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetSSSCategory(item) {
  try {
    const response = yield call(getSSSCategory, item.users)
    yield put(getSSSCategorySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateSSSCategory({ payload: user }) {
  try {
    const response = yield call(updateSSSCategory, user)
    yield put(updateSSSCategorySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}







function* onDeleteSiparis({ payload: user }) {
  try {
    const response = yield call(deleteSiparis, user)
    yield put(deleteSiparisSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddSiparis({ payload: user }) {
  try {
    const response = yield call(addSiparis, user)

    yield put(addSiparisSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetSiparis(item) {
  try {

    console.log("lsdfk",item.payload)
    if(item.payload == null){
      const response = yield call(getSiparis, item.payload)
    yield put(getSiparisSuccess(response))
      }else{
        const response = yield call(getSiparisRest, item.payload)
        yield put(getSiparisSuccess(response))
      }

   
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateSiparis({ payload: user }) {
  try {
    const response = yield call(updateSiparis, user)
    yield put(updateSiparisSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}











function* onDeleteEng({ payload: user }) {
  try {
    const response = yield call(deleteEng, user)
    yield put(deleteEngSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddEng({ payload: user }) {
  try {
    const response = yield call(addEng, user)

    yield put(addEngSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetEng(item) {
  try {
    const response = yield call(getEng, item.users)
    yield put(getEngSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateEng({ payload: user }) {
  try {
    const response = yield call(updateEng, user)
    yield put(updateEngSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}









function* onDeleteCons({ payload: user }) {
  try {
    const response = yield call(deleteCons, user)
    yield put(deleteConsSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddCons({ payload: user }) {
  try {
    const response = yield call(addCons, user)

    yield put(addConsSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetCons(item) {
  try {
    const response = yield call(getCons, item.users)
    yield put(getConsSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateCons({ payload: user }) {
  try {
    const response = yield call(updateCons, user)
    yield put(updateConsSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}










function* onDeleteOffer({ payload: user }) {
  try {
    const response = yield call(deleteOffer, user)
    yield put(deleteOfferSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddOffer({ payload: user }) {
  try {
    const response = yield call(addOffer, user)

    yield put(addReservationSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetOffer(item) {
  try {
    const response = yield call(getOffer, item.users)
    yield put(getOfferSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateOffer({ payload: user }) {
  try {
    const response = yield call(updateOffer, user)
    yield put(updateOfferSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}





function* onDeleteReservation({ payload: user }) {
  try {
    const response = yield call(deleteReservation, user)
    yield put(deleteReservationSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddReservation({ payload: user }) {
  try {
    const response = yield call(addReservation, user)

    yield put(addReservationSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetReservation(item) {
  try {
    const response = yield call(getReservation, item.users)
    yield put(getReservationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateReservation({ payload: user }) {
  try {
    const response = yield call(updateReservation, user)
    yield put(updateReservationSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}




function* onDeleteProduct({ payload: user }) {
  try {
    const response = yield call(deleteProduct, user)
    yield put(deleteProductSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddProduct({ payload: user }) {

  try {
    const response = yield call(addProduct, user)

    yield put(addProductSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onUpdateProduct({ payload: user }) {
  try {
    const response = yield call(updateProduct, user)
    yield put(updateProductSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onDeleteSss({ payload: user }) {
  try {
    const response = yield call(deleteSSS, user)
    yield put(deleteSSSSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddSSS({ payload: user }) {

  try {
    const response = yield call(addSSS, user)

    yield put(addSSSSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onUpdateSSS({ payload: user }) {
  try {
    const response = yield call(updateSSS, user)
    yield put(updateSSSSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onGetSSS(item) {
  try {
    const response = yield call(getSSS, item.users)
    yield put(getSSSSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}




function* onDeleteBlog({ payload: user }) {
  try {
    const response = yield call(deleteBlog, user)
    yield put(deleteBlogSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddSubEducation({ payload: user }) {

  try {
    const response = yield call(addSubEducation, user)

    yield put(addSubEducationSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onUpdateSubEducation({ payload: user }) {
  try {
    const response = yield call(updateSubEducation, user)
    yield put(updateSubEducationSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateEducation({ payload: user }) {
  try {
    const response = yield call(updateEducation, user)
    yield put(updateEducationSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateContact({ payload: user }) {
  try {
    const response = yield call(updateContact, user)
    yield put(updateContactSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateAbout({ payload: user }) {
  try {
    const response = yield call(updateAbout, user)
    yield put(updateAboutSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateDynamicForm({ payload: user }) {
  try {
    const response = yield call(updateDynamicForm, user)
    yield put(updateDynamicFormSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onGetEducationSubItem(item) {
  try {
    const response = yield call(getEducationSubItem, item.users)
    yield put(getSubEducationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetEducation(item) {
  try {
    const response = yield call(getEducation, item.users)
    yield put(getEducationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* ongetProductCategory(item) {
  try {
    const response = yield call(getProductCategory, item.users)
    console.log("response", response)
    yield put(getProductCategorySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetProduct(item) {
  try {
    const response = yield call(getProducts1, item.users)
    yield put(getProductSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetSliders(item) {
  try {
    const response = yield call(getSliders, item.users)
    yield put(getSlidersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetBlogs(item) {
  try {
    const response = yield call(getBlogs, item.users)
    console.log("kbsdf", response)
    yield put(getBlogsSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetUsers(item) {
  try {
    const response = yield call(getUsers, item.users)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetDynamicFormExtraExtra(item) {
  try {
    const response = yield call(getDynamicFormExtraExtraMega, item.users)
    yield put(getDynamicFormExtraExtraSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetDynamicFormExtra(item) {
  try {
    const response = yield call(getDynamicFormExtraMega, item.users)
    yield put(getDynamicFormExtraSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetClasses() {
  try {
    const response = yield call(getClassesMega)
    yield put(getClassesSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetClassAttendance() {
  try {
    const response = yield call(getClassAttendance)
    yield put(getClassAttendanceSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetTeacherWorkshopMega() {
  try {
    const response = yield call(getTeacherWorkshop)
    yield put(getTeacherWorkshopSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetSubProject(item) {
  try {
    const response = yield call(getSubProject(item.news))
    yield put(getSubProjectSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateLessonsTime({ payload: user }) {
  try {
    const response = yield call(updateLessonsTime, user)
    yield put(updateLessonsTimeSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}



function* onAddEducation({ payload: user }) {

  try {
    const response = yield call(addEducation, user)

    yield put(addEducationSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}





function* onAddLessonsTime({ payload: user }) {

  try {
    const response = yield call(addLessonsTime, user)

    yield put(addSuccessLessonsTime(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddReferances({ payload: user }) {

  try {
    const response = yield call(addReferances, user)

    yield put(addSuccessReferances(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onGetReferances() {
  try {
    const response = yield call(getReferances)
    yield put(getReferancesSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchGallery() {
  try {
    const response = yield call(getGallery)
    yield put(getGallerySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchAuthority() {
  try {
    const response = yield call(getAuthority)
    yield put(getAuthSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchContact() {
  try {
    const response = yield call(getContact)
    yield put(getMegaSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchAbout() {
  try {
    const response = yield call(getAbout)
    yield put(getAboutSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateAuth({ payload: user }) {
  try {
    const response = yield call(updateAuth, user)
    yield put(getAuthUpdateSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateReferances({ payload: user }) {
  try {
    const response = yield call(updateReferances, user)
    yield put(updateSuccessReferances(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateProject({ payload: user }) {
  try {
    const response = yield call(updateProject, user)
    yield put(updateSuccessProject(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateBlog({ payload: user }) {
  try {
    const response = yield call(updateBlog, user)
    yield put(updateSuccessBlog(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}



function* onUpdateProductCategory({ payload: user }) {
  try {
    const response = yield call(updateProductCategory, user)
    console.log("jlnsdfl", response)
    yield put(updateSuccessProductCategory(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}




function* onUpdateTeacher({ payload: user }) {
  try {
    const response = yield call(updateTeacher, user)
    yield put(updateTeacherSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateWorkshopSession({ payload: user }) {
  try {
    const response = yield call(updateWorkshopSession, user)
    yield put(updateWorkShopSessionSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateSlider({ payload: user }) {
  try {
    const response = yield call(updateSlider, user)
    yield put(updateSliderSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateSubdomain({ payload: user }) {
  try {
    const response = yield call(updateSubdomain, user)
    yield put(updateSubdomainSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* fetchMega() {
  try {
    const response = yield call(getMegaMega)
    yield put(getMegaSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchNews() {
  try {
    const response = yield call(getNews)
    yield put(getNewsSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchProjects() {
  try {
    const response = yield call(getProjectsNew)
    yield put(getProjectSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchSlider() {
  try {
    const response = yield call(getSlider)
    yield put(getSliderSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetcDynamicFormMega() {
  try {
    const response = yield call(getDynamicFormMega)
    yield put(getDynamicSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetcEventMega() {
  try {
    const response = yield call(getEventMega)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetcTeacherMega(user) {
  try {
    console.log("şsdfmşkl",user.users)
    if(user.users == null){
      const response = yield call(getTeacherMega)
      yield put(getTeacherSuccess(response))
    }else{
      console.log("şlkmsdlşf",user.users)
      const response = yield call(getTeacherMega1, user.users)
      yield put(getTeacherSuccess(response))
    }
  
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshopSessionMega() {
  try {
    const response = yield call(getWorkshopSessionMega)
    yield put(getApplicationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* fetchCourseSchedulesMega() {
  try {
    const response = yield call(getCourseSchedulesMega)
    yield put(getWorkshopSessionSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshop2() {
  try {
    const response = yield call(getWorkshop2)
    yield put(getWorkshop2Success(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchPopup() {
  try {
    const response = yield call(getPopup)
    console.log("response123", response)
    yield put(getPopupSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshop() {
  try {
    const response = yield call(getWorkShop)
    yield put(getWorkshopSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUsers() {
  try {
    const response = yield call(getUsers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUserProfile() {
  try {
    const response = yield call(getUserProfile)
    yield put(getUserProfileSuccess(response))
  } catch (error) {
    yield put(getUserProfileFail(error))
  }
}

function* onUpdateCourseSchedules({ payload: user }) {
  try {
    const response = yield call(updateCourseSchedules, user)
    yield put(updateSuccessCourseSchedules(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateNews({ payload: user }) {
  try {
    const response = yield call(updateNews, user)
    yield put(updateNewsSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateGalllery({ payload: user }) {
  try {
    const response = yield call(updateGallery, user)
    yield put(updateGallerySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateWorkshop2({ payload: user }) {
  try {
    const response = yield call(updateWorkShop2, user)
    yield put(updateWorkShop2Success(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateMega({ payload: user }) {
  try {
    const response = yield call(updateMega, user)
    yield put(updateMegaSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user)
    yield put(updateUserSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user)
    console.log("klsmdfklds", response, user)
    yield put(deleteUserSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}



function* onAddNewTeacher({ payload: user }) {

  try {
    const response = yield call(addNewTeacher, user)

    yield put(addNewTeacherSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddDynamicForm({ payload: user }) {

  try {
    const response = yield call(addDynamicForm, user)

    yield put(addDynamicFormSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAdddNewWorkshop2({ payload: user }) {

  try {
    const response = yield call(addWorkshop2, user)

    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddWorkshopSession({ payload: user }) {

  try {
    const response = yield call(addAppSetting, user)

    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddWorkshopSubdomain({ payload: user }) {

  try {
    const response = yield call(addNewSubdomain, user)

    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onAddNewUser({ payload: user }) {

  try {
    const response = yield call(addNewUser, user)

    yield put(addUserSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddCourse({ payload: user }) {

  try {
    const response = yield call(addCourseMega, user)

    console.log("response123213", response)
    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onAddNewMega({ payload: user }) {

  try {
    const response = yield call(addMega, user)

    console.log("respocccc13", response.value[0])
    yield put(addMegaSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddNews({ payload: user }) {

  try {
    const response = yield call(addNews, user)

    console.log("response123213", response)
    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddProject({ payload: user }) {

  try {
    const response = yield call(addProject, user)

    console.log("response123213", response)
    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onAddPopup({ payload: user }) {

  try {
    const response = yield call(addPopup, user)

    console.log("response123213", response)
    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onDeleteMega({ payload: user }) {
  try {
    const response = yield call(deleteMega, user)
    console.log("user123", user)
    yield put(deleteMegaSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteProductCategory({ payload: user }) {
  try {
    const response = yield call(deleteProductCategory, user)
    console.log("user123", user)
    yield put(deleteProductCategorySuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteNews({ payload: user }) {
  try {
    const response = yield call(deleteNews, user)
    console.log("user123", user)
    yield put(deleteNewsSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteSlider({ payload: user }) {
  try {
    const response = yield call(deleteSlider, user)
    console.log("user123", user)
    yield put(deleteSliderSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeletePopup({ payload: user }) {
  try {
    const response = yield call(deletePopup, user)
    console.log("user123", user)
    yield put(deletePopupSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteReferance({ payload: user }) {
  try {
    const response = yield call(deleteReferances, user)
    console.log("user123", user)
    yield put(deleteReferancesSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onAddGallery({ payload: user }) {

  try {
    const response = yield call(addGallerry, user)

    yield put(addGallerySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onAddProductCategory({ payload: user }) {

  try {
    const response = yield call(addProductCategory, user)

    yield put(addProductCategorySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}




function* onAddUser({ payload: user }) {

  try {
    const response = yield call(addUser, user)

    yield put(addUserSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddBlog({ payload: user }) {

  try {
    const response = yield call(addBlog, user)

    yield put(addBlogSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onDeleteGallery({ payload: user }) {
  try {
    const response = yield call(deleteGallery, user)
    yield put(deleteGallerySuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddMultipleGallery({ payload: user }) {

  try {
    const response = yield call(addMultipleGalleryForm, user)

    //yield put(addGallerySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onDeleteCourseSchedules({ payload: user }) {
  try {
    const response = yield call(deleteCourseSchedules, user)
    yield put(deleteCourseSchedulesSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicFormExtra({ payload: user }) {
  try {
    const response = yield call(deleteDynamicFormExtra, user)
    yield put(deleteDynamicExtraFormSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicForm({ payload: user }) {
  try {
    const response = yield call(deleteDynamicForm, user)
    yield put(deleteDynamicFormSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicFormExtraExtra({ payload: user }) {
  try {
    const response = yield call(deleteDynamicFormExtraExtra, user)
    yield put(deleteDynamicExtraExtraFormSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* contactsSaga() {
  
  yield takeEvery(ADD_NEW_TEACHER, onAddNewTeacher)

  yield takeEvery(GET_RESERVATION, onGetReservation)
  yield takeEvery(DELETE_RESERVATION, onDeleteReservation)

  yield takeEvery(ADD_RESERVATION, onAddReservation)

  yield takeEvery(UPDATE_RESERVATION, onUpdateReservation)


  yield takeEvery(GET_CONS, onGetCons)
  yield takeEvery(DELETE_CONS, onDeleteCons)

  yield takeEvery(ADD_CONS, onAddCons)

  yield takeEvery(UPDATE_CONS, onUpdateCons)





  yield takeEvery(GET_AVAILABLE, onGetAvailable)
  yield takeEvery(DELETE_AVAILABLE, onDeleteAvailable)

  yield takeEvery(ADD_AVAILABLE, onAddAvailable)

  yield takeEvery(UPDATE_AVAILABLE, onUpdateAvailable)


  yield takeEvery(DELETE_SUBEDUCATION, onDeleteSubEducation)


  yield takeEvery(DELETE_PRODUCT, onDeleteProduct)
  yield takeEvery(ADD_PRODUCT, onAddProduct)

  yield takeEvery(UPDATE_PRODUCT, onUpdateProduct)

  

  yield takeEvery(DELETE_ADRESS, onDeleteAdress)
  yield takeEvery(ADD_ADRESS, onAddAdress)
  yield takeEvery(GET_ADRESS, onGetAdress)
  yield takeEvery(UPDATE_ADRESS, onUpdateAdress)

  yield takeEvery(DELETE_BLOG, onDeleteBlog)

  yield takeEvery(DELETE_SSS, onDeleteSss)
  yield takeEvery(ADD_SSS, onAddSSS)
  yield takeEvery(GET_SSS, onGetSSS)
  yield takeEvery(UPDATE_SSS, onUpdateSSS)


  yield takeEvery(DELETE_REST_ADRESS, onDeleteRest_Adress)
  yield takeEvery(ADD_REST_ADRESS, onAddRest_Adress)
  yield takeEvery(GET_REST_ADRESS, onGetRest_Adress)
  yield takeEvery(UPDATE_REST_ADRESS, onUpdateRest_Adress)

  yield takeEvery(DELETE_REST_PRODUCT, onDeleteRest_Product)
  yield takeEvery(ADD_REST_PRODUCT, onAddRest_Product)
  yield takeEvery(GET_REST_PRODUCT, onGetRest_Product)
  yield takeEvery(UPDATE_REST_PRODUCT, onUpdateRest_Product)


  yield takeEvery(DELETE_ORDERS, onDeleteOrd)
  yield takeEvery(ADD_ORDERS, onAddOrd)
  yield takeEvery(GET_ORDERS, onGetOrd)
  yield takeEvery(UPDATE_ORDERS, onUpdateOrd)


  yield takeEvery(DELETE_REST_CATEGORY, onDeleteRest_Category)
  yield takeEvery(ADD_REST_CATEGORY, onAddRest_Category)
  yield takeEvery(GET_REST_CATEGORY, onGetRest_Category)
  yield takeEvery(UPDATE_REST_CATEGORY, onUpdateRest_Category)



  yield takeEvery(DELETE_SSS_CATEGORY, onDeleteSSSCategory)
  yield takeEvery(ADD_SSS_CATEGORY, onAddSSSCategory)
  yield takeEvery(GET_SSS_CATEGORY, onGetSSSCategory)
  yield takeEvery(UPDATE_SSS_CATEGORY, onUpdateSSSCategory)

  yield takeEvery(DELETE_REST, onDeleteRest)
  yield takeEvery(ADD_REST, onAddRest)
  yield takeEvery(GET_REST, onGetRest)
  yield takeEvery(UPDATE_REST, onUpdateRest)


  yield takeEvery(DELETE_COMPANY, onDeleteCompany)
  yield takeEvery(ADD_COMPANY, onAddCompany)
  yield takeEvery(GET_COMPANY, onGetCompany)
  yield takeEvery(UPDATE_COMPANY, onUpdateCompany)



  yield takeEvery(DELETE_SIPARIS, onDeleteSiparis)
  yield takeEvery(ADD_SIPARIS, onAddSiparis)
  yield takeEvery(GET_SIPARIS, onGetSiparis)
  yield takeEvery(UPDATE_SIPARIS, onUpdateSiparis)


  yield takeEvery(DELETE_ENG, onDeleteEng)
  yield takeEvery(ADD_ENG, onAddEng)
  yield takeEvery(GET_ENG, onGetEng)
  yield takeEvery(UPDATE_ENG, onUpdateEng)

  yield takeEvery(DELETE_OFFER, onDeleteOffer)
  yield takeEvery(ADD_OFFER, onAddOffer)
  yield takeEvery(GET_OFFER, onGetOffer)
  yield takeEvery(UPDATE_OFFER, onUpdateOffer)

  yield takeEvery(ADD_SUBEDUCATION, onAddSubEducation)

  yield takeEvery(UPDATE_SUBEDUCATION, onUpdateSubEducation)

  yield takeEvery(ADD_EDUCATION, onAddEducation)

  yield takeEvery(UPDATE_EDUCATION, onUpdateEducation)

  yield takeEvery(GET_SUBEDUCATION, onGetEducationSubItem)

  yield takeEvery(GET_EDUCATION, onGetEducation)
  yield takeEvery(GET_PRODUCTCATEGORY, ongetProductCategory)

  yield takeEvery(GET_PRODUCT, onGetProduct)

  yield takeEvery(GET_SLİDERS, onGetSliders)
  yield takeEvery(GET_BLOGS, onGetBlogs)

  yield takeEvery(GET_USERS, onGetUsers)

  yield takeEvery(DELETE_DYNAMICFORMEXTRAEXTRA, onDeleteDynamicFormExtraExtra)

  yield takeEvery(DELETE_DYNAMICFORM, onDeleteDynamicForm)

  yield takeEvery(DELETE_DYNAMICFORMEXTRA, onDeleteDynamicFormExtra)

  yield takeEvery(DELETE_COURSESCHEDULES, onDeleteCourseSchedules)

  yield takeEvery(ADD_GALLERYMULTIPLE, onAddMultipleGallery)

  yield takeEvery(DELETE_GALLERY, onDeleteGallery)

  yield takeEvery(ADD_GALLERY, onAddGallery)


  yield takeEvery(ADD_PRODUCTCATEGORY, onAddProductCategory)

  yield takeEvery(ADD_BLOG, onAddBlog)

  yield takeEvery(DELETE_REFERANCES, onDeleteReferance)

  yield takeEvery(DELETE_POPUP, onDeletePopup)

  yield takeEvery(DELETE_SLIDER, onDeleteSlider)

  yield takeEvery(DELETE_NEWS, onDeleteNews)

  yield takeEvery(DELETE_MEGA, onDeleteMega)
  yield takeEvery(DELETE_PRODUCTCATEGORY, onDeleteProductCategory)

  yield takeEvery(GET_DYNAMICFORM_EXTRA_EXTRA, onGetDynamicFormExtraExtra)

  yield takeEvery(GET_DYNAMICFORM_EXTRA, onGetDynamicFormExtra)

  yield takeEvery(ADD_DYNAMIC_FORM, onAddDynamicForm)

  yield takeEvery(UPDATE_DYNAMICFORM, onUpdateDynamicForm)

  yield takeEvery(GET_CLASSES, onGetClasses)

  yield takeEvery(GET_CLASSATTENDANCE, onGetClassAttendance)

  yield takeEvery(UPDATE_LESSONS_TIME, onUpdateLessonsTime)

  yield takeEvery(ADD_LESSONS_TIME, onAddLessonsTime)

  yield takeEvery(GET_TEACHER_WORKSHOP, onGetTeacherWorkshopMega)

  yield takeEvery(GET_SUBPROJECT, onGetSubProject)

  yield takeEvery(UPDATE_REFERANCES, onUpdateReferances)

  yield takeEvery(ADD_REFERANCES, onAddReferances)

  yield takeEvery(GET_REFERANCES, onGetReferances)

  yield takeEvery(ADD_WORKSHOP2, onAdddNewWorkshop2)

  yield takeEvery(ADD_APPSETTING, onAddWorkshopSession)

  yield takeEvery(ADD_WORKSHOPSUBDOMAIN, onAddWorkshopSubdomain)

  yield takeEvery(UPDATE_GALLERY, onUpdateGalllery)

  yield takeEvery(GET_GALLERY, fetchGallery)

  yield takeEvery(UPDATE_WORKSHOP2, onUpdateWorkshop2)

  yield takeEvery(GET_WORKSHOP2, fetchWorkshop2)

  yield takeEvery(UPDATE_MEGA, onUpdateMega)

  yield takeEvery(UPDATE_PROJECT, onUpdateProject)

  yield takeEvery(UPDATE_BLOG, onUpdateBlog)
  yield takeEvery(UPDATE_PRODUCTCATEGORY, onUpdateProductCategory)

  yield takeEvery(UPDATE_SLIDER, onUpdateSlider)

  yield takeEvery(UPDATE_COURSESCHEDULES, onUpdateCourseSchedules)

  yield takeEvery(UPDATE_AUTH, onUpdateAuth)

  yield takeEvery(GET_AUTHORITY, fetchAuthority)

  yield takeEvery(UPDATE_CONTACT, onUpdateContact)

  yield takeEvery(UPDATE_ABOUT, onUpdateAbout)

  yield takeEvery(GET_CONTACT, fetchContact)
  yield takeEvery(GET_ABOUT, fetchAbout)

  yield takeEvery(UPDATE_TEACHER, onUpdateTeacher)

  yield takeEvery(UPDATE_WORKSHOPSESSION, onUpdateWorkshopSession)

  yield takeEvery(UPDATE_SUBDOMAIN, onUpdateSubdomain)




  yield takeEvery(ADD_COURSE, onAddCourse)

  yield takeEvery(UPDATE_NEWS, onUpdateNews)

  yield takeEvery(ADD_MEGA, onAddNewMega)

  yield takeEvery(ADD_NEWS, onAddNews)

  yield takeEvery(ADD_PROJECT, onAddProject)

  yield takeEvery(ADD_SLIDER, onAddSlider)

  yield takeEvery(ADD_POPUP, onAddPopup)

  yield takeEvery(GET_MEGA, fetchMega)

  yield takeEvery(GET_NEWS, fetchNews)

  yield takeEvery(GET_PROJECTSNEW, fetchProjects)

  yield takeEvery(GET_SLIDER, fetchSlider)

  yield takeEvery(GET_DYNAMICFORM, fetcDynamicFormMega)

  yield takeEvery(GET_EVENT, fetcEventMega)

  yield takeEvery(GET_TeacherMega, fetcTeacherMega)

  yield takeEvery(GET_CourseSchedulesMega, fetchCourseSchedulesMega)

  yield takeEvery(GET_WorkshopSessionMega, fetchWorkshopSessionMega)

  yield takeEvery(GET_WORKSHOP, fetchWorkshop)

  yield takeEvery(GET_POPUP, fetchPopup)

  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(ADD_NEW_USER, onAddUser)

  
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
}

export default contactsSaga;

export const DELETE_SUBEDUCATION = "DELETE_SUBEDUCATION"
export const DELETE_SUBEDUCATION_SUCCESS = "DELETE_SUBEDUCATION_SUCCESS"

export const UPDATE_SUBEDUCATION = "UPDATE_SUBEDUCATION"
export const UPDATE_SUBEDUCATION_SUCCESS = "UPDATE_SUBEDUCATION_SUCCESS"

export const ADD_SUBEDUCATION = "ADD_SUBEDUCATION"
export const ADD_SUBEDUCATION_SUCCESS = "ADD_SUBEDUCATION_SUCCESS"


export const GET_ABOUT_SUCCESS = "GET_ABOUT_SUCCESS"

export const UPDATE_EDUCATION_SUCCESS = "UPDATE_EDUCATION_SUCCESS"
export const UPDATE_EDUCATION = "UPDATE_EDUCATION"

export const ADD_EDUCATION = "ADD_EDUCATION"
export const ADD_EDUCATION_SUCCESS = "ADD_EDUCATION_SUCCESS"

export const GET_SUBEDUCATION = "GET_SUBEDUCATION"
export const GET_SUBEDUCATION_SUCCESS = "GET_SUBEDUCATION_SUCCESS"




export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"

export const DELETE_ORDERS = "DELETE_ORDERS"
export const DELETE_ORDERS_SUCCESS = "DELETE_ORDERS_SUCCESS"
export const ADD_NEW_TEACHER = "ADD_NEW_TEACHER"
export const ADD_NEW_TEACHER_SUCCESS = "ADD_NEW_TEACHER_SUCCESS"

export const UPDATE_ORDERS_SUCCESS = "UPDATE_ORDERS_SUCCESS"
export const UPDATE_ORDERS = "UPDATE_ORDERS"

export const ADD_ORDERS = "ADD_ORDERS"
export const ADD_ORDERS_SUCCESS = "ADD_ORDERS_SUCCESS"



export const GET_REST = "GET_REST"
export const GET_REST_SUCCESS = "GET_REST_SUCCESS"

export const DELETE_REST = "DELETE_REST"
export const DELETE_REST_SUCCESS = "DELETE_REST_SUCCESS"



export const UPDATE_REST_SUCCESS = "UPDATE_REST_SUCCESS"
export const UPDATE_REST = "UPDATE_REST"

export const ADD_REST = "ADD_REST"
export const ADD_REST_SUCCESS = "ADD_REST_SUCCESS"




export const GET_ADRESS = "GET_ADRESS"
export const GET_ADRESS_SUCCESS = "GET_ADRESS_SUCCESS"

export const DELETE_ADRESS = "DELETE_ADRESS"
export const DELETE_ADRESS_SUCCESS = "DELETE_ADRESS_SUCCESS"



export const UPDATE_ADRESS_SUCCESS = "UPDATE_ADRESS_SUCCESS"
export const UPDATE_ADRESS = "UPDATE_ADRESS"

export const ADD_ADRESS = "ADD_ADRESS"
export const ADD_ADRESS_SUCCESS = "ADD_ADRESS_SUCCESS"




export const GET_REST_PRODUCT = "GET_REST_PRODUCT"
export const GET_REST_PRODUCT_SUCCESS = "GET_REST_PRODUCT_SUCCESS"

export const DELETE_REST_PRODUCT = "DELETE_REST_PRODUCT"
export const DELETE_REST_PRODUCT_SUCCESS = "DELETE_REST_PRODUCT_SUCCESS"



export const UPDATE_REST_PRODUCT_SUCCESS = "UPDATE_REST_PRODUCT_SUCCESS"
export const UPDATE_REST_PRODUCT = "UPDATE_REST_PRODUCT"

export const ADD_REST_PRODUCT = "ADD_REST_PRODUCT"
export const ADD_REST_PRODUCT_SUCCESS = "ADD_REST_PRODUCT_SUCCESS"




export const GET_REST_ADRESS = "GET_REST_ADRESS"
export const GET_REST_ADRESS_SUCCESS = "GET_REST_ADRESS_SUCCESS"

export const DELETE_REST_ADRESS = "DELETE_REST_ADRESS"
export const DELETE_REST_ADRESS_SUCCESS = "DELETE_REST_ADRESS_SUCCESS"



export const UPDATE_REST_ADRESS_SUCCESS = "UPDATE_REST_ADRESS_SUCCESS"
export const UPDATE_REST_ADRESS = "UPDATE_REST_ADRESS"

export const ADD_REST_ADRESS = "ADD_REST_ADRESS"
export const ADD_REST_ADRESS_SUCCESS = "ADD_REST_ADRESS_SUCCESS"



export const GET_REST_CATEGORY = "REST_CATEGORY"
export const GET_REST_CATEGORY_SUCCESS = "GET_REST_CATEGORY_SUCCESS"

export const DELETE_REST_CATEGORY = "DELETE_REST_CATEGORY"
export const DELETE_REST_CATEGORY_SUCCESS = "DELETE_REST_CATEGORY_SUCCESS"








export const UPDATE_SSS_CATEGORY_SUCCESS = "UPDATE_SSS_CATEGORY_SUCCESS"
export const UPDATE_SSS_CATEGORY = "UPDATE_SSS_CATEGORY"

export const ADD_SSS_CATEGORY = "ADD_SSS_CATEGORY"
export const ADD_SSS_CATEGORY_SUCCESS = "ADD_SSS_CATEGORY_SUCCESS"



export const GET_SSS_CATEGORY = "GET_SSS_CATEGORY"
export const GET_SSS_CATEGORY_SUCCESS = "GET_SSS_CATEGORY_SUCCESS"

export const DELETE_SSS_CATEGORY = "DELETE_SSS_CATEGORY"
export const DELETE_SSS_CATEGORY_SUCCESS = "DELETE_SSS_CATEGORY_SUCCESS"






export const UPDATE_REST_CATEGORY_SUCCESS = "UPDATE_REST_CATEGORY_SUCCESS"
export const UPDATE_REST_CATEGORY = "UPDATE_REST_CATEGORY"

export const ADD_REST_CATEGORY = "ADD_REST_CATEGORY"
export const ADD_REST_CATEGORY_SUCCESS = "ADD_REST_CATEGORY_SUCCESS"


export const GET_OFFER = "GET_OFFER"
export const GET_OFFER_SUCCESS = "GET_OFFER_SUCCESS"

export const GET_SIPARIS = "GET_SIPARIS"
export const GET_SIPARIS_SUCCESS = "GET_SIPARIS_SUCCESS"

export const ADD_SIPARIS = "ADD_SIPARIS"
export const ADD_SIPARIS_SUCCESS = "ADD_SIPARIS_SUCCESS"



export const UPDATE_SIPARIS = "UPDATE_SIPARIS"
export const UPDATE_SIPARIS_SUCCESS = "UPDATE_SIPARIS_SUCCESS"

export const DELETE_SIPARIS = "DELETE_SIPARIS"
export const DELETE_SIPARIS_SUCCESS = "DELETE_SIPARIS_SUCCESS"



export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS"
export const UPDATE_OFFER = "UPDATE_OFFER"

export const ADD_OFFER  = "ADD_OFFER"
export const ADD_OFFER_SUCCESS = "ADD_OFFER_SUCCESS"








export const DELETE_OFFER = "DELETE_OFFER"
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS"

export const GET_COMPANY = "GET_COMPANY"
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS"

export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"



export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY = "UPDATE_COMPANY"

export const ADD_COMPANY  = "ADD_COMPANY"
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS"



export const GET_ENG = "GET_ENG"
export const GET_ENG_SUCCESS = "GET_ENG_SUCCESS"

export const DELETE_ENG = "DELETE_ENG"
export const DELETE_ENG_SUCCESS = "DELETE_ENG_SUCCESS"



export const UPDATE_ENG_SUCCESS = "UPDATE_ENG_SUCCESS"
export const UPDATE_ENG = "UPDATE_ENG"

export const ADD_ENG  = "ADD_ENG"
export const ADD_ENG_SUCCESS = "ADD_ENG_SUCCESS"




export const GET_AVAILABLE = "GET_AVAILABLE"
export const GET_AVAILABLE_SUCCESS = "GET_AVAILABLE_SUCCESS"

export const DELETE_AVAILABLE = "DELETE_AVAILABLE"
export const DELETE_AVAILABLE_SUCCESS = "DELETE_AVAILABLE_SUCCESS"



export const UPDATE_AVAILABLE_SUCCESS = "UPDATE_AVAILABLE_SUCCESS"
export const UPDATE_AVAILABLE = "UPDATE_AVAILABLE"

export const ADD_AVAILABLE  = "ADD_AVAILABLE"
export const ADD_AVAILABLE_SUCCESS = "ADD_AVAILABLE_SUCCESS"



export const GET_RESERVATION = "GET_RESERVATION"
export const GET_RESERVATION_SUCCESS = "GET_RESERVATION_SUCCESS"

export const DELETE_RESERVATION = "DELETE_RESERVATION"
export const DELETE_RESERVATION_SUCCESS = "DELETE_RESERVATION_SUCCESS"



export const UPDATE_RESERVATION_SUCCESS = "UPDATE_EDUCATION_SUCCESS"
export const UPDATE_RESERVATION  = "UPDATE_EDUCATION"

export const ADD_RESERVATION  = "ADD_EDUCATION"
export const ADD_RESERVATION_SUCCESS = "ADD_EDUCATION_SUCCESS"


export const GET_PRODUCTCATEGORY = "GET_PRODUCTCATEGORY"
export const GET_PRODUCTCATEGORY_SUCCESS = "GET_PRODUCTCATEGORY_SUCCESS"

export const GET_PRODUCT = "GET_PRODUCT"
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS"

export const GET_SLİDERS = "GET_SLİDERS"
export const GET_SLİDERS_SUCCESS = "GET_SLİDERS_SUCCESS"

export const GET_BLOGS = "GET_BLOGS"
export const GET_BLOGS_SUCCESS = "GET_BLOGS_SUCCESS"

export const GET_SSS = "GET_SSS"
export const GET_SSS_SUCCESS = "GET_SSS_SUCCESS"

export const DELETE_SSS = "DELETE_SSS"
export const DELETE_SSS_SUCCESS = "DELETE_SSS_SUCCESS"

export const GET_EDUCATION = "GET_EDUCATION"
export const GET_EDUCATION_SUCCESS = "GET_EDUCATION_SUCCESS"

export const DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS = "DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS"
export const DELETE_DYNAMICFORMEXTRAEXTRA = "DELETE_DYNAMICFORMEXTRAEXTRA"

export const DELETE_DYNAMICFORM = "DELETE_DYNAMICFORM"
export const DELETE_DYNAMICFORM_SUCCESS = "DELETE_DYNAMICFORM_SUCCESS"

export const DELETE_DYNAMICFORMEXTRA = "DELETE_DYNAMICFORMEXTRA"
export const DELETE_DYNAMICFORMEXTRA_SUCCESS = "DELETE_DYNAMICFORMEXTRA_SUCCESS"

export const DELETE_COURSESCHEDULES = "DELETE_COURSESCHEDULES"
export const DELETE_COURSESCHEDULES_SUCCESS = "DELETE_COURSESCHEDULES_SUCCESS"

export const ADD_GALLERYMULTIPLE = "ADD_GALLERYMULTIPLE"
export const ADD_GALLERYMULTIPLE_SUCCESS = "ADD_GALLERYMULTIPLE_SUCCESS"

export const DELETE_GALLERY = "DELETE_GALLERY"
export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS"

export const ADD_GALLERY = "ADD_GALLERY"
export const ADD_GALLERY_SUCCESS = "ADD_GALLERY_SUCCESS"


export const ADD_PRODUCTCATEGORY = "ADD_PRODUCTCATEGORY"
export const ADD_PRODUCTCATEGORY_SUCCESS = "ADD_PRODUCTCATEGORY_SUCCESS"



export const ADD_SSS = "ADD_SSS"
export const ADD_SSS_SUCCESS = "ADD_SSS_SUCCESS"


export const ADD_BLOG= "ADD_BLOG"
export const ADD_BLOG_SUCCESS = "ADD_BLOG_SUCCESS"

export const DELETE_REFERANCES = "DELETE_REFERANCES"
export const DELETE_REFERANCES_SUCCESS = "DELETE_REFERANCES_SUCCESS"

export const DELETE_POPUP = "DELETE_POPUP"
export const DELETE_POPUP_SUCCESS = "DELETE_POPUP_SUCCESS"

export const DELETE_PRODUCTCATEGORY_SUCCESS = "DELETE_PRODUCTCATEGORY_SUCCESS"
export const DELETE_PRODUCTCATEGORY = "DELETE_PRODUCTCATEGORY"



export const ADD_PRODUCT= "ADD_PRODUCT"
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS"

export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"

export const UPDATE_SSS= "UPDATE_SSS"
export const UPDATE_SSS_SUCCESS = "UPDATE_SSS_SUCCESS"

export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
export const DELETE_PRODUCT = "DELETE_PRODUCT"

export const DELETE_SLIDER_SUCCESS = "DELETE_SLIDER_SUCCESS"
export const DELETE_SLIDER = "DELETE_SLIDER"


export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS"
export const DELETE_BLOG = "DELETE_BLOG"

export const DELETE_NEWS = "DELETE_NEWS"
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS"
export const DELETE_MEGA_SUCCESS = "DELETE_MEGA_SUCCESS"
export const DELETE_MEGA = "DELETE_MEGA"
export const ADD_MEGA_SUCCESS = "ADD_MEGA_SUCCESS"

export const GET_DYNAMICFORM_EXTRA_EXTRA = "GET_DYNAMICFORM_EXTRA_EXTRA"
export const GET_DYNAMICFORMEXTRAEXTRA_SUCCESS = "GET_DYNAMICFORMEXTRAEXTRA_SUCCESS"
export const GET_DYNAMICFORMEXTRA_SUCCESS = "GET_DYNAMICFORMEXTRA_SUCCESS"
export const GET_DYNAMICFORM_EXTRA = "GET_DYNAMICFORM_EXTRA"
export const GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS"
export const GET_CLASSES = "GET_CLASSES"
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"


export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS"
export const GET_GALLERY = "GET_GALLERY"

export const UPDATE_WORKSHOP2_SUCCESS = "UPDATE_WORKSHOP2_SUCCESS"

export const UPDATE_SUBDOMAIN = "UPDATE_SUBDOMAIN"

export const UPDATE_NEWS = "UPDATE_NEWS"

export const UPDATE_PROJECT = "UPDATE_PROJECT"

export const UPDATE_DYNAMICFORM = "UPDATE_DYNAMICFORM"
export const GET_DYNAMICFORM_SUCCESS = "GET_DYNAMICFORM_SUCCESS"
export const UPDATE_DYNAMICFORM_SUCCESS = "UPDATE_DYNAMICFORM_SUCCESS"


export const ADD_DYNAMICFORM_SUCCESS = "ADD_DYNAMICFORM_SUCCESS"

export const UPDATE_SLIDER = "UPDATE_SLIDER"
export const UPDATE_SLIDER_SUCCESS = "UPDATE_SLIDER_SUCCESS"

export const UPDATE_SUCCESS_PROJECT = "UPDATE_SUCCESS_PROJECT"
export const UPDATE_SUCCESS_GALLERY = "UPDATE_SUCCESS_GALLERY"

export const UPDATE_COURSESCHEDULES = "UPDATE_COURSESCHEDULES"
export const UPDATE_SUBDOMAIN_SUCCESS = "UPDATE_SUBDOMAIN_SUCCESS"
export const UPDATE_WORKSHOPSESSION_SUCCESS = "UPDATE_WORKSHOPSESSION_SUCCESS"
export const UPDATE_SUCCESS_COURSESCHEDULES = "UPDATE_SUCCESS_COURSESCHEDULES"
export const UPDATE_SUCCESS_TEACHER = "UPDATE_SUCCESS_TEACHER"

export const UPDATE_SUCCESS_NEWS = "UPDATE_SUCCESS_NEWS"
export const UPDATE_SUCCESS_MEGA = "UPDATE_SUCCESS_MEGA"
export const UPDATE_MEGA = "UPDATE_MEGA"
export const UPDATE_WORKSHOP2 = "UPDATE_WORKSHOP2"
export const UPDATE_GALLERY = "UPDATE_GALLERY"
export const UPDATE_REFERANCES = "UPDATE_REFERANCES"
export const UPDATE_REFERANCES_SUCCESS = "UPDATE_REFERANCES_SUCCESS"

export const UPDATE_BLOG = "UPDATE_BLOG"
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS"
export const UPDATE_PRODUCTCATEGORY = "UPDATE_PRODUCTCATEGORY"
export const UPDATE_PRODUCTCATEGORY_SUCCESS = "UPDATE_PRODUCTCATEGORY_SUCCESS"
export const GET_TEACHER_WORKSHOP = "GET_TEACHER_WORKSHOP"
export const GET_WORKSHOP2_SUCCESS = "GET_WORKSHOP2_SUCCESS"
export const GET_SUBPROJECT_SUCCESS = "GET_SUBPROJECT_SUCCESS"


export const GET_CONS = "GET_CONS"

export const GET_CONS_SUCCESS = "GET_CONS_SUCCESS"

export const UPDATE_CONS = "UPDATE_CONS"
export const UPDATE_CONS_SUCCESS = "UPDATE_CONS_SUCCESS"
export const GET_AUTHORITY = "GET_AUTHORITY"
export const GET_CONTACT = "GET_CONTACT"
export const GET_ABOUT = "GET_ABOUT"
export const UPDATE_CONTACT = "UPDATE_CONTACT"
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS"
export const UPDATE_ABOUT = "UPDATE_ABOUT"
export const UPDATE_ABOUT_SUCCESS = "UPDATE_ABOUT_SUCCESS"

export const GET_AUTH_SUCCESS = "GET_AUTH_SUCCESS"
export const UPDATE_AUTH = "UPDATE_AUTH"
export const GET_WORKSHOP2 = "GET_WORKSHOP2"
export const GET_REFERANCES = "GET_REFERANCES"
export const GET_REFERANCES_SUCCESS = "GET_REFERANCES_SUCCESS"
export const ADD_REFERANCES = "ADD_REFERANCES"
export const ADD_REFERANCES_SUCCESS = "ADD_REFERANCES_SUCCESS"
export const ADD_DYNAMIC_FORM = "ADD_DYNAMIC_FORM"
export const ADD_SLIDER = "ADD_SLIDER"
export const ADD_SLIDER_SUCCESS = "ADD_SLIDER_SUCCESS"

export const GET_AUTHUPDATE_SUCCESS = "GET_AUTHUPDATE_SUCCESS"
export const GET_SUBPROJECT = "GET_SUBPROJECT"
export const GET_CLASSATTENDANCE = "GET_CLASSATTENDANCE"
export const GET_CLASSATTENDANCE_SUCCESS = "GET_CLASSATTENDANCE_SUCCESS"

export const UPDATE_LESSONS_TIME = "UPDATE_LESSONS_TIME"
export const ADD_LESSONSTIME_SUCCESS = "ADD_LESSONSTIME_SUCCESS"
export const ADD_LESSONS_TIME = "ADD_LESSONS_TIME"
export const ADD_WORKSHOP2 = "ADD_WORKSHOP2"
export const ADD_APPSETTING = "ADD_APPSETTING"
export const ADD_WORKSHOPSUBDOMAIN = "ADD_WORKSHOPSUBDOMAIN"
export const ADD_COURSE = "ADD_COURSE"
export const ADD_MEGA = "ADD_MEGA"
export const ADD_NEWS = "ADD_NEWS"
export const ADD_PROJECT = "ADD_PROJECT"

export const ADD_POPUP = "ADD_POPUP"
export const UPDATE_TEACHER = "UPDATE_TEACHER"
export const UPDATE_WORKSHOPSESSION = "UPDATE_WORKSHOPSESSION"
export const UPDATE_POPUP = "UPDATE_POPUP"
export const GET_POPUP_SUCCESS = "GET_POPUP_SUCCESS"
export const UPDATE_POPUP_SUCCESS = "UPDATE_POPUP_SUCCESS"
export const GET_TEACHERWORKSHOP_SUCCESS = "GET_TEACHERWORKSHOP_SUCCESS"
export const UPDATE_LESSONSTIME_SUCCESS = "UPDATE_LESSONSTIME_SUCCESS"

export const GET_WORKSHOP_SUCCESS = "GET_WORKSHOP_SUCCESS"
export const GET_WORKSHOPSESSION_SUCCESS = "GET_WORKSHOPSESSION_SUCCESS"
export const GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS"
export const GET_SLIDER_SUCCESS = "GET_SLIDER_SUCCESS"
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS"
export const GET_MEGA_SUCCESS = "GET_MEGA_SUCCESS"
export const GET_TEACHER_SUCCESS = "GET_TEACHER_SUCCESS"
export const GET_DYNAMIC_SUCCESS = "GET_DYNAMIC_SUCCESS"

/* POPUP */
export const GET_POPUP = "GET_POPUP"
export const GET_WORKSHOP = "GET_WORKSHOP"
export const GET_CourseSchedulesMega = "GET_CourseSchedulesMega"
export const GET_WorkshopSessionMega = "GET_WorkshopSessionMega"
export const GET_TeacherMega = "GET_TeacherMega"
export const GET_EVENT = "GET_EVENT"
export const GET_DYNAMICFORM = "GET_DYNAMICFORM"
export const GET_SLIDER = "GET_SLIDER"
export const GET_PROJECTSNEW = "GET_PROJECTSNEW"
export const GET_NEWS = "GET_NEWS"
export const GET_MEGA = "GET_MEGA"

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"


/**
 * add user
 */
 export const ADD_CONS= "ADD_CONS"
 export const ADD_CONS_SUCCESS = "ADD_CONS_SUCCESS"
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

/**
 * Edit user
 */
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/**
 * Delete user
 */
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"
export const DELETE_CONS = "DELETE_CONS"
export const DELETE_CONS_SUCCESS = "DELETE_CONS_SUCCESS"
import axios from "axios";
import { del, get, post, put, patch } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

// get Products
export const getProducts = () => get(url.GET_PRODUCTS);

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } });

// get Events

// add Events

// update Event


// delete Event
export const deleteEvent = project =>
  del(`https://bittigitti.stechomeyazilim.info:5151/deleteEventMega/${project}`);


// get Categories

// get chats

// get groups
export const getGroups = () => get(url.GET_GROUPS);

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS);

// get messages


// post messages
export const getselectedmails = (selectedmails) => post(url.GET_SELECTED_MAILS, selectedmails);

//post setfolderonmails
export const setfolderonmails = (selectedmails, folderId, activeTab) => post(url.SET_FOLDER_SELECTED_MAILS, { selectedmails, folderId, activeTab });

// get orders
export const getOrders = () => get(url.GET_ORDERS);

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order);

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order);

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } });

// get cart data
export const getCartData = () => get(url.GET_CART_DATA);

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS);

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer);

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer);

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } });

// get shops
export const getShops = () => get(url.GET_SHOPS);

// get wallet
export const getWallet = () => get(url.GET_WALLET);

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS);

// get crypto product
export const getCryptoProduct = () => get(url.GET_CRYPTO_PRODUCTS);

// get invoices
export const getInvoices = () => get(url.GET_INVOICES);

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } });

// get jobs
export const getJobList = () => get(url.GET_JOB_LIST);

// get Apply Jobs
export const getApplyJob = () => get(url.GET_APPLY_JOB);

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// get tasks
export const getTasks = () => get(url.GET_TASKS);

export const addSubEducation = user => post(`https://bittigitti.stechomeyazilim.info:5151/addSubEducationNefes/send`, user);
export const updateSubEducation = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateSubEducationNefes/${user.ID}`, user);
export const deleteSubEducation = user => del(`https://bittigitti.stechomeyazilim.info:5151/deleteSubEducation/${user}`);
export const addEducation = user => post(`https://bittigitti.stechomeyazilim.info:5151/addEducationNefes/send`, user);
export const addProductCategory = user => post(`https://bittigitti.stechomeyazilim.info:5151/addProduct_CategoryBitti/send`, user);
export const deleteProductCategory = user => del(`https://bittigitti.stechomeyazilim.info:5151/deleteProduct_CategoryBitti/${user}`);
export const updateProductCategory = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateProduct_CategoryBitti/${user.ID}`, user);

export const updateEducation = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateEducationNefes/${user.ID}`, user);

export const updateAbout = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateAboutBitti/${user.ID}`, user);
export const updateContact = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateContactBitti/${user.ID}`, user);
export const getProductCategory = () => get(`https://bittigitti.stechomeyazilim.info:5151/getProduct_CategoryBitti/select/`);
export const getProducts1 = () => get(`https://bittigitti.stechomeyazilim.info:5151/getProductsBitti/select/`);
export const getSliders = () => get(`https://bittigitti.stechomeyazilim.info:5151//getSliderBitti/select/`);


export const getAvailable = () => get(`https://bittigitti.stechomeyazilim.info:5151/getAvailablePortsPort/select/`);
export const updateAvailable = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateAvailablePortsPort/${user.ID}`, user);
export const addAvailable = job => post(`https://bittigitti.stechomeyazilim.info:5151/addAvailablePortsPort/send`, job);
export const deleteAvailable = project =>
del(`https://bittigitti.stechomeyazilim.info:5151/deleteAvailablePortsPort/${project}`);




export const getCompany = () => get(`https://bittigitti.stechomeyazilim.info:5151/getCompanyBitti/select/`);
export const updateCompany = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateCompanyBitti/${user.ID}`, user);
export const addCompany= job => post(`https://bittigitti.stechomeyazilim.info:5151/addCompanyBitti/send`, job);
export const deleteCompany= project =>
del(`https://bittigitti.stechomeyazilim.info:5151/deleteCompanyBitti/${project}`);



export const getReservation = () => get(`https://bittigitti.stechomeyazilim.info:5151/getReservationPort/select/`);
export const updateReservation = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateSliderCastor/${user.ID}`, user);
export const addReservation = job => post(`https://bittigitti.stechomeyazilim.info:5151/addSliderCastor/send`, job);
export const deleteReservation = project =>
del(`https://bittigitti.stechomeyazilim.info:5151/deleteSSSPort/${project}`);


export const getOffer = () => get(`https://bittigitti.stechomeyazilim.info:5151/getOfferPort/select/`);
export const updateOffer = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateSliderPort/${user.ID}`, user);
export const addOffer = job => post(`https://bittigitti.stechomeyazilim.info:5151/addSliderPort/send`, job);
export const deleteOffer = project =>
del(`https://bittigitti.stechomeyazilim.info:5151/deleteSSSPort/${project}`);



export const getOrd = () => get(`https://bittigitti.stechomeyazilim.info:5151/getComplateOrderLuxun/select/`);
export const updateOrd = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateSliderPort/${user.ID}`, user);
export const addOrd = job => post(`https://bittigitti.stechomeyazilim.info:5151/addSliderPort/send`, job);
export const deleteOrd = project =>del(`https://bittigitti.stechomeyazilim.info:5151/deleteSSSPort/${project}`);

export const getRest_Product = () => get(`https://bittigitti.stechomeyazilim.info:5151/getRest_ProductBitti/select/`);
export const updateRest_Product = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateRest_ProductBitti/${user.ID}`, user);
export const addRest_Product = job => post(`https://bittigitti.stechomeyazilim.info:5151/addRest_ProductBitti/send`, job);
export const deleteRest_Product = project =>del(`https://bittigitti.stechomeyazilim.info:5151/deleteRest_ProductBitti/${project}`);

export const getRestAdress = () => get(`https://bittigitti.stechomeyazilim.info:5151/getRest_AdressBitti/select/`);

export const getRestAdressRest = (roomId = "") => get(`https://bittigitti.stechomeyazilim.info:5151/getRestAdressBittiRest/select/${roomId}`);
export const getRest_ProductRest = (roomId = "") => get(`https://bittigitti.stechomeyazilim.info:5151/getRestProductRest/select/${roomId}`);
export const getCompanyRest = (roomId = "") => get(`https://bittigitti.stechomeyazilim.info:5151/getCompanyRest/select/${roomId}`);



export const updateRestAdress = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateRest_AdressBitti/${user.ID}`, user);
export const addRestAdress = job => post(`https://bittigitti.stechomeyazilim.info:5151/addRest_AdressBitti/send`, job);
export const deleteRestAdress = project =>del(`https://bittigitti.stechomeyazilim.info:5151/deleteRest_AdressBitti/${project}`);

export const getAdress = () => get(`https://bittigitti.stechomeyazilim.info:5151/getAdressBitti/select/`);
export const updateAdress = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateAdressBitti/${user.ID}`, user);
export const addAdress = job => post(`https://bittigitti.stechomeyazilim.info:5151/addAdressBitti/send`, job);
export const deleteAdress = project =>del(`https://bittigitti.stechomeyazilim.info:5151/deleteAdressBitti/${project}`);


export const getRest = () => get(`https://bittigitti.stechomeyazilim.info:5151/getRestBitti/select/`);
export const updateRest = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateRestBitti/${user.ID}`, user);
export const addRest = job => post(`https://bittigitti.stechomeyazilim.info:5151/addRestBitti/send`, job);
export const deleteRest = project =>del(`https://bittigitti.stechomeyazilim.info:5151/deleteRestBitti/${project}`);
export const addNewTeacher = job => post(`https://bittigitti.stechomeyazilim.info:5151/postRestPaymentBitti/send`, job);



export const getSSSCategory = () => get(`https://bittigitti.stechomeyazilim.info:5151/getSSSCategoryBitti/select/`);
export const updateSSSCategory = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateSSSCategoryBitti/${user.ID}`, user);
export const addSSSCategory = job => post(`https://bittigitti.stechomeyazilim.info:5151/addSSSCategoryBitti/send`, job);
export const deleteSSSCategory = project =>del(`https://bittigitti.stechomeyazilim.info:5151/deleteSSSCategoryBitti/${project}`);



export const getRest_Category = () => get(`https://bittigitti.stechomeyazilim.info:5151/getRest_CategoryBitti/select/`);
export const updateRest_Category = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateRest_CategoryBitti/${user.ID}`, user);
export const addRest_Category = job => post(`https://bittigitti.stechomeyazilim.info:5151/addRest_CategoryBitti/send`, job);
export const deleteRest_Category = project =>del(`https://bittigitti.stechomeyazilim.info:5151/deleteRest_CategoryBitti/${project}`);

export const getSiparisRest = roomId => get(`https://bittigitti.stechomeyazilim.info:5151/getOrderRest/select/${roomId}`);

export const getSiparis = () => get(`https://bittigitti.stechomeyazilim.info:5151/getComplated_Order_Bitti/select/`);
export const updateSiparis = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateComplated_Order_DetailBitti/${user.ID}`, user);
export const addSiparis = job => post(`https://bittigitti.stechomeyazilim.info:5151/addSliderPort/send`, job);
export const deleteSiparis = project =>del(`https://bittigitti.stechomeyazilim.info:5151/deleteSSSPort/${project}`);

export const getSSS = () => get(`https://bittigitti.stechomeyazilim.info:5151/getSSSBitti/select/`);
export const getBlogs = () => get(`https://bittigitti.stechomeyazilim.info:5151/getBlogBitti/select/`);
export const getUsers = () => get(`https://bittigitti.stechomeyazilim.info:5151/getUsersBitti/select/`);
export const addNewUser = user => post(`https://bittigitti.stechomeyazilim.info:5151/postRestPaymentBitti/send`, user);
export const deleteUser = user => del(`https://bittigitti.stechomeyazilim.info:5151/deleteUsersBitti/${user}`);
export const getProjectsNew = () => get(`https://bittigitti.stechomeyazilim.info:5151/getEducationNefes/select/`);
export const getEducation = () => get(`https://bittigitti.stechomeyazilim.info:5151/getEducationNefes/select/`);
export const getEducationSubItem = () => get(`https://bittigitti.stechomeyazilim.info:5151/getEducationSubItemNefes/select/`);
export const getPopup = () => get(`https://bittigitti.stechomeyazilim.info:5151/getPopupMega/select/`);
export const getWorkShop = () => get(`https://bittigitti.stechomeyazilim.info:5151/getSUBDOMAINMega/select/`);

export const getCourseSchedulesMega = () => get(`https://bittigitti.stechomeyazilim.info:5151/getCourseSchedulesMega/select/`);

export const getWorkshopSessionMega = () => get(`https://bittigitti.stechomeyazilim.info:5151/getWorkshopSessionMega/select/`);

export const getTeacherMega = () => get(`https://bittigitti.stechomeyazilim.info:5151/getRestPaymentBitti/select/`);
export const getTeacherMega1 = user => get(`https://bittigitti.stechomeyazilim.info:5151/getRestPaymentBittiSearch/select/${user}`);

export const getEventMega = () => get(`https://bittigitti.stechomeyazilim.info:5151/getEventMega/select/`);

export const getDynamicFormMega = () => get(`https://bittigitti.stechomeyazilim.info:5151/getDynamicFormMega/select/`);

export const getSlider = () => get(`https://bittigitti.stechomeyazilim.info:5151/getSliderCastor/select/`);


export const getNews = () => get(`https://bittigitti.stechomeyazilim.info:5151/getNewsMega/select/`);

export const getMegaMega = () => get(`https://bittigitti.stechomeyazilim.info:5151/getMegaMega/select/`);

export const getMailsLists = () => get(`https://bittigitti.stechomeyazilim.info:5151/getContactMessageMega/select/`);

// add user

const postFakeLogin = data => get(`https://bittigitti.stechomeyazilim.info:5151/getUserLoginBitti/select/${data.email}/${data.password}`,);

export const getEvents = () => get(`https://bittigitti.stechomeyazilim.info:5151/getEventMega/select/`);
export const getAbout = () => get(`https://bittigitti.stechomeyazilim.info:5151/getAboutBitti/select/`);
export const getCategories = () => get(`https://bittigitti.stechomeyazilim.info:5151/getEventCategoryMega/select/`);

export const getCons = () => get(`https://bittigitti.stechomeyazilim.info:5151/getConsultancyCastor/select/`);


export const getEng = () => get(`https://bittigitti.stechomeyazilim.info:5151/getEngineeringServicesCastor/select/`);
// update user

export const updatePopup = user => patch(`https://bittigitti.stechomeyazilim.info:5151/patchPopupMega/${user.ID}`, user);

export const updateSubDomain = user => patch(`https://bittigitti.stechomeyazilim.info:5151/patchSUBDOMAINMega/${user.ID}`, user);

export const updateCourseSchedules = user => patch(`https://bittigitti.stechomeyazilim.info:5151/patchCourseSchedulesMega/${user.ID}`, user);

export const updateWorkshopSession = user => patch(`https://bittigitti.stechomeyazilim.info:5151/patchWorkShopSessionMega/${user.ID}`, user);

export const updateTeacher = user => patch(`https://bittigitti.stechomeyazilim.info:5151/patchRestPaymentBitti/${user.ID}`, user);

export const updateSlider = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateSliderBitti/${user.ID}`, user);

export const updateSSS = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateSSSBitti/${user.ID}`, user);

export const updateProduct = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateProductsBitti/${user.ID}`, user);
export const updateBlog = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateBlogBitti/${user.ID}`, user);
export const updateUser = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateUsersBitti/${user.ID}`, user);
export const updateCons = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateConsultancyCastor/${user.ID}`, user);
export const updateEng = user => patch(`https://bittigitti.stechomeyazilim.info:5151/updateEngineeringServicesCastor/${user.ID}`, user);
export const updateProject = user => patch(`https://bittigitti.stechomeyazilim.info:5151/patchUpdateProject/${user.ID}`, user);

export const updateNews = user => patch(`https://bittigitti.stechomeyazilim.info:5151/patchNews/${user.ID}`, user);

export const addPopup = job => post(`https://bittigitti.stechomeyazilim.info:5151/addPopup/send`, job);

export const addSlider = job => post(`https://bittigitti.stechomeyazilim.info:5151/addSliderBitti/send`, job);

export const addSSS= job => post(`https://bittigitti.stechomeyazilim.info:5151/addSSSBitti/send`, job);
export const addProduct = job => post(`https://bittigitti.stechomeyazilim.info:5151/addProductsBitti/send`, job);
export const addUser = job => post(`https://bittigitti.stechomeyazilim.info:5151/addUsersBitti/send`, job);

export const addCons = job => post(`https://bittigitti.stechomeyazilim.info:5151/addConsultancyCastor/send`, job);
export const addEng = job => post(`https://bittigitti.stechomeyazilim.info:5151/addEngineeringServicesCastor/send`, job);
export const addBlog = job => post(`https://bittigitti.stechomeyazilim.info:5151/addBlogBitti/send`, job);
export const addProject = job => post(`https://bittigitti.stechomeyazilim.info:5151/addProject/send`, job);

export const addNews = job => post(`https://bittigitti.stechomeyazilim.info:5151/addNews/send`, job);

export const addMega = job => post(`https://bittigitti.stechomeyazilim.info:5151/addMega/send`, job);

export const addCourseMega = job => post(`https://bittigitti.stechomeyazilim.info:5151/addCourseMega/send`, job);

export const updateSubdomain = user => patch(`https://bittigitti.stechomeyazilim.info:5151/patchSUBDOMAINMega/${user.ID}`, user);



export const updateAuth = user => patch(`https://bittigitti.stechomeyazilim.info:5151/patchAuthMega/${user.ID}`, user);

export const updateMega = user => patch(`https://bittigitti.stechomeyazilim.info:5151/patchMegaMega/${user.ID}`, user);

export const updateGallery = user => patch(`https://bittigitti.stechomeyazilim.info:5151/patchGalleryMega/${user.ID}`, user);

export const updateEvent = event => patch(`https://bittigitti.stechomeyazilim.info:5151/patchEventMega/${event.ID}`, event);

export const addAppSetting = job => post(`https://bittigitti.stechomeyazilim.info:5151/postWSMega/send`, job);

export const addWorkshop2 = job => post(`https://bittigitti.stechomeyazilim.info:5151/postWorkshopMega/send`, job);



// delete user

export const getContact = () => get(`https://bittigitti.stechomeyazilim.info:5151/getContactBitti/select/`);
export const getAuthority = () => get(`https://bittigitti.stechomeyazilim.info:5151/getUsersTypeMega/select/`);
export const getWorkshop2 = () => get(`https://bittigitti.stechomeyazilim.info:5151/getWorkshopMega/select/`);

export const updateWorkShop2 = user => patch(`https://bittigitti.stechomeyazilim.info:5151/patchWorkshop2Mega/${user.ID}`, user);

export const getGallery = () => get(`https://bittigitti.stechomeyazilim.info:5151/getGalleryMega/select/`);

export const addNewEvent = event => post(`https://bittigitti.stechomeyazilim.info:5151/postEventMega/send/`, event);

export const getChats = () => get(`https://bittigitti.stechomeyazilim.info:5151/getContactMessageMega/select/`);

export const getMessages = (roomId = "") => get(`https://bittigitti.stechomeyazilim.info:5151/getContactMessageDetailMega/select/${roomId}`);

export const addNewSubdomain = event => post(`https://bittigitti.stechomeyazilim.info:5151/postSubdomainMega/send/`, event);
export const getReferances = () => get(`https://bittigitti.stechomeyazilim.info:5151/getReferancesMega/select/`);
// add jobs
export const addNewJobList = job => post(url.ADD_NEW_JOB_LIST, job);
// update jobs
export const updateJobList = job => put(url.UPDATE_JOB_LIST, job);
// delete jobs
export const deleteJobList = job => del(url.DELETE_JOB_LIST, { headers: { job } });
// Delete Apply Jobs
export const deleteApplyJob = data => del(url.DELETE_APPLY_JOB, { headers: { data } });
/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project);

export const addReferances = event => post(`https://bittigitti.stechomeyazilim.info:5151/postReferancesMega/send/`, event);

export const updateReferances = user => patch(`https://bittigitti.stechomeyazilim.info:5151/patchReferancesMega/${user.ID}`, user);

export const getSubProject = (ID = "") => get(`https://bittigitti.stechomeyazilim.info:5151/getProjectSubItem/select/${ID}`);

export const getTeacherWorkshop = () => get(`https://bittigitti.stechomeyazilim.info:5151/getTeacherWorkshopMega/select/`);

export const addLessonsTime = event => post(`https://bittigitti.stechomeyazilim.info:5151/postLessonsTimeMega/send/`, event);

export const updateLessonsTime = user => patch(`https://bittigitti.stechomeyazilim.info:5151/patchTeacherWorkshopMega/${user.ID}`, user);

export const getClassAttendance = () => get(`https://bittigitti.stechomeyazilim.info:5151/getClassAttendanceMega/select/`);

export const getClassesMega = () => get(`https://bittigitti.stechomeyazilim.info:5151/getClassesMega/select/`);

export const updateDynamicForm = user => patch(`https://bittigitti.stechomeyazilim.info:5151/patchDynanicFormMega/${user.ID}`, user);

export const addDynamicForm = job => post(`https://bittigitti.stechomeyazilim.info:5151/postDynamicFormMega/send`, job);

export const getDynamicFormExtraMega = (ID = "") => get(`https://bittigitti.stechomeyazilim.info:5151/getDynamicFormExtraMega/select/${ID}`);

export const getDynamicFormExtraExtraMega = (ID = "") => get(`https://bittigitti.stechomeyazilim.info:5151/getDynamicFormExtraExtraMega/select/${ID}`);


export const addGallerry = job => post(`https://bittigitti.stechomeyazilim.info:5151/postGalleryMega/send`, job);

export const addMultipleGalleryForm = job => post(`https://bittigitti.stechomeyazilim.info:5151/postGalleryMultipleMega/send`, job);


export const deleteDynamicFormExtraExtra = project =>
  del(`https://bittigitti.stechomeyazilim.info:5151/deleteDynamicExtraExtraFormMega/${project}`);

export const deleteDynamicForm = project =>
  del(`https://bittigitti.stechomeyazilim.info:5151/deleteDynamicFormMega/${project}`);

export const deleteDynamicFormExtra = project =>
  del(`https://bittigitti.stechomeyazilim.info:5151/deleteDynamicFormExtraMega/${project}`);

export const deleteCourseSchedules = project =>
  del(`https://bittigitti.stechomeyazilim.info:5151/deleteCourseSchedulesMega/${project}`);

export const deleteGallery = project =>
  del(`https://bittigitti.stechomeyazilim.info:5151/deleteGalleryMega/${project}`);

export const deleteReferances = project =>
  del(`https://bittigitti.stechomeyazilim.info:5151/deleteReferancesMega/${project}`);

export const deletePopup = project =>
  del(`https://bittigitti.stechomeyazilim.info:5151/deletePopupMega/${project}`);

export const deleteSlider = project =>
  del(`https://bittigitti.stechomeyazilim.info:5151/deleteSliderBitti/${project}`);
  

  export const deleteSSS = project =>
  del(`https://bittigitti.stechomeyazilim.info:5151/deleteSSSBitti/${project}`);
  



  export const deleteCons= project =>
del(`https://bittigitti.stechomeyazilim.info:5151/deleteConsultancyCastor/${project}`);


export const deleteEng= project =>
del(`https://bittigitti.stechomeyazilim.info:5151/deleteEngineeringServicesCastor/${project}`);


export const deleteProduct = project =>
del(`https://bittigitti.stechomeyazilim.info:5151/deleteProductsBitti/${project}`);

  export const deleteBlog = project =>
  del(`https://bittigitti.stechomeyazilim.info:5151/deleteBlogBitti/${project}`);


  

export const deleteNews = project =>
  del(`https://bittigitti.stechomeyazilim.info:5151/deleteNewsMega/${project}`);

export const deleteMega = project =>
  del(`https://bittigitti.stechomeyazilim.info:5151/deleteMega/${project}`);

export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);

// get maillist
//update mail
export const updateMail = mail => put(url.UPDATE_MAIL, mail);

// get folderlist
export const selectFolders = () => get(url.SELECT_FOLDER);

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message);

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA);
export const getYearlyData = () => get(url.GET_YEARLY_DATA);
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA);

export const walletBalanceData = month =>
  get(`${url.GET_WALLET_DATA}/${month}`, { params: { month } });

export const getStatisticData = duration =>
  get(`${url.GET_STATISTICS_DATA}/${duration}`, { params: { duration } });

export const visitorData = duration =>
  get(`${url.GET_VISITOR_DATA}/${duration}`, { params: { duration } });

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } });

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS);

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  });
};
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  });
};

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  });
};

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  });
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment,
};